import { Box, Container, Typography } from '@mui/material'
import CardMedia from '@mui/material/CardMedia';
import React from 'react'
import Fond from '../assets/fond-accueil.jpeg';

function Page404() {

    return (
        <Container
            maxWidth='none'
            sx={{
                m: 0, p: 0,
                fontFamily: 'Tomorrow, sans-serif',
                height: '100vh',
                overflow: 'hidden',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                backgroundImage: `url(${Fond})`,
                backgroundSize: 'cover',
                minHeight: '100vh',
                backgroundAttachment: 'fixed',
            }}>
            <Box className="text" sx={{ position: 'absolute', top: '10%', color: 'white', textAlign: 'center' }}>
                <Box className="error-page gradient" sx={{ fontSize: '100px' }}>ERROR</Box>
                <Typography variant="h1" sx={{ fontSize: '150px' }}>404</Typography>
                <hr />
                <Box sx={{ fontSize: '50px' }}>Oops! La page est introuvable</Box>
            </Box>
            <Box className="astronaut">
                <CardMedia
                    component="img"
                    image="https://images.vexels.com/media/users/3/152639/isolated/preview/506b575739e90613428cdb399175e2c8-space-astronaut-cartoon-by-vexels.png"
                    alt=""
                    className="src"
                />
            </Box>
        </Container>
    )
}

export default Page404
