import React, { useState, useEffect } from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import MainLayout from '../layouts/MainLayout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ListItem } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Dialog from '@mui/material/Dialog';
import { urlImg } from "../utils/url";
import Footer from '../components/Footer';
import ScrollButton from '../components/ScrollButton';

import { getImageInscription } from '../store/actions/ImageInscriptionActions';
import { getYears } from '../store/actions/YearsInscriptionActions';
import { getTarifs } from '../store/actions/TarifsActions';
import { getProcedures } from '../store/actions/ProceduresActions';
import { getLink } from '../store/actions/LinkActions';
import { useSelector, useDispatch } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 18,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        backgroundColor: '#fff',
        color: '#000'
    },
}));

export default function Inscription() {

    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const years = useSelector((state) => state.years.yearsData);
    const procedure = useSelector((state) => state.procedures.proceduresData);
    const link = useSelector((state) => state.link.linkData);
    const tarif = useSelector((state) => state.tarifs.tarifsData);
    const imageInscription = useSelector((state) => state.imageInscription.imageInscriptionData);
    const handleClose = () => { setOpenDialog(false) };
    const handleOpenImage = () => { setOpenDialog(true) }
    useEffect(() => {
        dispatch(getTarifs());
        dispatch(getYears());
        dispatch(getProcedures());
        dispatch(getLink());
        dispatch(getImageInscription())
    }, [dispatch]);

    return (
        <MainLayout>
            <CssBaseline />

            {/* Titre */}
            <Box component="div" sx={{ pt: 5, padding: '85px 16px 24px' }}>
                <Typography variant='h2' align='center' sx={{ fontSize: { xs: "2.5em", md: "5em" }, my: 2 }} >
                    Inscription
                </ Typography>
                <Typography variant='h2' align='center' sx={{ fontSize: { xs: "2.5em", md: "5em" }, my: 2 }} >
                    Saison
                </ Typography>
                {years.length > 0 && (
                    <Typography variant='h2' align='center' sx={{ fontSize: { xs: "2.5em", md: "5em" }, my: 2 }} >
                        {years[0].annee}
                    </ Typography>
                )}
            </Box>

            <Box sx={{ display: { xs: 'block', lg: 'flex' }, justifyContent: 'space-around', my: 10 }}>
                {/* Procedures */}
                <Box
                    sx={{
                        background: '#1F2124',
                        boxShadow: '0 0 20px rgba(0,0,0,0.4)',
                        borderRadius: '5px',
                        width: { xs: '80%', lg: 'calc(33.3333% - 40px)' },
                        p: '20px',
                        textAlign: 'center',
                        color: 'white',
                        mt: '20px',
                        mx: 'auto'
                    }}
                >
                    <Typography variant="h3" sx={{ fontSize: '25px' }}>Procédures</Typography>
                    <Box sx={{ m: '50px 0' }}>
                        <DescriptionIcon sx={{ width: "100px", height: "100px", fill: "#F6B352" }} />
                    </Box>
                    <Box className='features'>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="body1" sx={{ display: 'inline', fontWeight: 'bolder', pr: 1 }}>
                                Pour nous rejoindre, c'est très simple, il faut que tu remplisses ton inscription en ligne en cliquant
                            </Typography>
                            {link.length > 0 && (
                                <Link variant="body1" sx={{ display: 'inline', color: 'blue' }} href={link[0].link} target='_blank'>
                                    ici
                                </Link>
                            )}
                        </Box>
                        <Typography variant="body1" sx={{ fontWeight: 'bolder', display: 'block' }}>
                            Pour nous faire parvenir les documents, tu peux:
                        </Typography>
                        <List>
                            {procedure.map((indice, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon><ArrowRightAltIcon /></ListItemIcon>
                                    <ListItemText primary={indice.procedure} />
                                </ListItem>
                            ))}
                        </List>
                        <Typography variant="body1" sx={{ fontWeight: 'bolder', display: 'block' }}>
                            Une fois le dossier traité, tu recevras un mail de confirmation automatique. S'il te manque des pièces, tu seras informé par mail et il faudra nous les emmener pour pouvoir commencer cette nouvelle saison.
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bolder', display: 'block' }}>
                            Si tout est en ordre,
                        </Typography>
                        <Typography variant="h4" align='center' sx={{ mt: 5, fontFamily: 'Permanent Marker, cursive' }}>
                            Nous te souhaitons la bienvenue!
                        </Typography>
                    </Box>
                </Box>

                {/* Horaire */}
                <Box
                    sx={{
                        background: '#1F2124',
                        boxShadow: '0 0 20px rgba(0,0,0,0.4)',
                        borderRadius: '5px',
                        width: { xs: '80%', lg: 'calc(33.3333% - 40px)' },
                        p: '20px',
                        textAlign: 'center',
                        color: 'white',
                        mt: '20px',
                        mx: 'auto'
                    }}
                >
                    <Typography variant="h3" sx={{ fontSize: '25px' }}>Planning</Typography>
                    <Box sx={{ m: '50px 0' }}>
                        <DateRangeIcon sx={{ width: "100px", height: "100px", fill: "#F6B352" }} />
                    </Box>
                    <Box className='features'>
                        {imageInscription.length > 0 && (
                            <Button onClick={() => handleOpenImage()}>
                                <CardMedia component="img" image={`${urlImg + imageInscription[0].image}`} alt="Planning" />
                            </Button>
                        )}
                    </Box>
                </Box>

                {/* Tarifs */}
                <Box
                    sx={{
                        background: '#1F2124',
                        boxShadow: '0 0 20px rgba(0,0,0,0.4)',
                        borderRadius: '5px',
                        width: { xs: '80%', lg: 'calc(33.3333% - 40px)' },
                        p: '20px',
                        textAlign: 'center',
                        color: 'white',
                        mt: '20px',
                        mx: 'auto'
                    }}
                >
                    <Typography variant="h3" sx={{ fontSize: '25px' }}>Tarifs</Typography>
                    <Box sx={{ m: '50px 0' }}>
                        <LocalOfferIcon sx={{ width: "100px", height: "100px", fill: "#F6B352" }} />
                    </Box>
                    <Box className='features'>
                        <TableContainer component={Paper} sx={{ maxWidth: 800, m: "auto", boxShadow: 'none' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Groupes</StyledTableCell>
                                        <StyledTableCell>Prix</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tarif.map((row, index) => (
                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                            <StyledTableCell>{row.activite}</StyledTableCell>
                                            <StyledTableCell>{row.prix}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <StyledTableCell colSpan={4}>
                                            Remise pour les bénévoles pratiquants responsables de groupe de -50%
                                        </StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>

            {/* Dialog pour ecran lg */}
            {imageInscription.length > 0 && (
                <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth='lg' sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <CardMedia component="img" image={`${urlImg + imageInscription[0].image}`} alt="Planning" />
                </Dialog>
            )}
            {/* Dialog pour ecran md */}
            {imageInscription.length > 0 && (
                <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth='md' sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}>
                    <CardMedia component="img" image={`${urlImg + imageInscription[0].image}`} alt="Planning" />
                </Dialog>
            )}

            {/* Dialog pour ecran sm */}
            {imageInscription.length > 0 && (
                <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth='sm' sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
                    <CardMedia component="img" image={`${urlImg + imageInscription[0].image}`} alt="Planning" />
                </Dialog>
            )}

            {/* Dialog pour ecran xs */}
            {imageInscription.length > 0 && (
                <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth='xs' sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <CardMedia component="img" image={`${urlImg + imageInscription[0].image}`} alt="Planning" />
                </Dialog>
            )}

            <ScrollButton />

            {/* Footer */}
            <Footer />

        </MainLayout >
    );
};