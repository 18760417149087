import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { urlImg } from '../../../utils/url';
import InputLabel from '@mui/material/InputLabel';
import ImageIcon from '@mui/icons-material/Image';

import { useSelector } from "react-redux";
import { getSalle, addSalle, deleteSalle } from '../../../store/actions/SalleActions';

function RowImage(props) {
    const { item, dispatch } = props
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    const handleDeleteDialog = () => { setOpenDeleteDialog(!openDeleteDialog) }
    const handleClickDeleteSalle = () => {
        dispatch(deleteSalle(item._id))
        setOpenDeleteDialog(false)
    }

    return (
        <ImageListItem>
            <img
                src={`${urlImg + item.image}`}
                alt={item.name}
                loading="lazy"
            />
            <ImageListItemBar
                sx={{ background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)' }}
                title={item.name}
                position="top"
                actionIcon={
                    <IconButton aria-label={`star ${item.name}`} onClick={() => handleDeleteDialog()}>
                        <DeleteIcon sx={{ color: '#ff0000' }} />
                    </IconButton>
                }
                actionPosition="right"
            />
            <Dialog onClose={() => handleDeleteDialog()} open={openDeleteDialog}>
                <DialogTitle sx={{ textDecoration: 'none' }}>
                    Voulez vous vraiment supprimer cette image?
                </DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                    <Button variant="contained" color="error" onClick={() => handleClickDeleteSalle()} sx={{ py: 2, width: 150 }}>
                        Oui
                    </Button>
                    <Button variant="contained" color="info" onClick={() => handleDeleteDialog()} sx={{ py: 2, width: 150 }}>
                        Non
                    </Button>
                </Box>
            </Dialog>
        </ImageListItem>
    )
}

function AddCollapse(props) {
    const { setOpenAdd, openAdd, dispatch } = props;
    const [image, setImage] = useState({});
    const [imagePreview, setImagePreview] = useState();
    const [preview, setPreview] = useState();
    const handleCloseAdd = () => { setOpenAdd(false); };
    const handleSubmit = (e) => {
        if (image.name) {
            const formData = new FormData();
            formData.append('image', image);
            dispatch(addSalle(formData));
            setImage('');
            setImagePreview('');
            setOpenAdd(false);
        } else {
            handleCloseAdd()
        }
    };
    const handleDeleteImage = () => { setImagePreview('') };
    useEffect(() => {
        if (!imagePreview) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(imagePreview)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [imagePreview])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setImagePreview(undefined)
            return
        }
        setImagePreview(e.target.files[0])
        setImage(e.target.files[0])
    }

    useEffect(() => { setTimeout(() => { dispatch(getSalle()) }, 4000) }, [dispatch, image]);

    return (
        <Collapse in={openAdd} timeout="auto" unmountOnExit>
            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ mb: 3, color: '#fff' }}>
                Ajouter une image:
            </Typography>
            <List sx={{ pt: 0 }}>
                <ListItem>
                    <TextField id="imageInput" sx={{ display: 'none' }} size="small" type="file" inputProps={{ accept: "image/*" }} onChange={onSelectFile} />
                    <InputLabel
                        sx={{
                            display: 'block',
                            color: '#FFF',
                            background: '#0288d1',
                            p: '20px',
                            fontSize: '1rem',
                            cursor: 'pointer',
                            width: '250px',
                            m: 'auto',
                            borderRadius: 2
                        }}
                        htmlFor="imageInput"
                        className="image-button"
                    >
                        <ImageIcon sx={{ fontSize: '125%', mr: '.3rem', verticalAlign: 'sub' }} />
                        Choisir une image
                    </InputLabel>
                </ListItem>
                {imagePreview &&
                    <ImageListItem sx={{ width: '300px', m: 'auto' }}>
                        <img src={preview} className="image-preview" alt='image_test' />
                        <ImageListItemBar
                            sx={{ background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)' }}
                            position="top"
                            actionIcon={
                                <IconButton aria-label={`star`} onClick={() => handleDeleteImage()}>
                                    <DeleteIcon sx={{ color: '#ff0000' }} />
                                </IconButton>
                            }
                            actionPosition="right"
                        />
                    </ImageListItem>
                }
                <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                    <Button variant="contained" color="info" onClick={() => handleSubmit()} sx={{ py: 2, width: 100 }}>
                        Valider
                    </Button>
                    <Button variant="contained" color="info" onClick={() => handleCloseAdd()} sx={{ py: 2, width: 100 }}>
                        Fermer
                    </Button>
                </Box>
            </List>
        </Collapse>
    );
}

export default function SalleCard({ dispatch }) {

    const [openAdd, setOpenAdd] = useState(false);
    const salle = useSelector((state) => state.salle.salleData);
    const handleClickOpen = () => { setOpenAdd(!openAdd); };

    useEffect(() => { dispatch(getSalle()); }, [dispatch]);

    return (
        <Card sx={{ mb: 5 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant='h5' align='center' gutterBottom>
                    Salle
                </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CardActions>
                    <ImageList cols={3}>
                        {salle.map((item, index) => (
                            // Liste d\'image
                            <RowImage key={index} item={item} dispatch={dispatch} />
                        ))}
                    </ImageList>
                </CardActions>
            </Box>
            <Box>
                <CardActions>
                    <Button
                        sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }}
                        variant="contained" size="medium" fullWidth
                        onClick={() => handleClickOpen()}
                    >
                        Ajouter
                    </Button>
                </CardActions>

                {/* Collapse ajout image */}
                <AddCollapse openAdd={openAdd} setOpenAdd={setOpenAdd} dispatch={dispatch} />
            </Box>
        </Card >
    );
}
