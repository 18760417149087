/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
    GET_JUGES_DATA,
    ADD_JUGES_DATA,
    DELETE_JUGES_DATA,
    EDIT_JUGES_DATA,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get juges
export const getJuges = () => {
    return (dispatch) => {
        return api
            .get("/juges/")
            .then((res) => { dispatch({ type: GET_JUGES_DATA, payload: res.data.dbJuges }) })
            .catch((err) => console.log(err));
    };
};

// Add juges
export const addJuges = (data) => {
    return (dispatch) => {
        return api
            .post("/juges/", data)
            .then((res) => { dispatch({ type: ADD_JUGES_DATA, payload: res.data.dbJuges }) })
            .catch((err) => console.log(err));
    };
};

// DeleteOne juges
export const deleteJuges = (id) => {
    return (dispatch) => {
        return api
            .delete(`/juges/${id}`)
            .then((res) => { dispatch({ type: DELETE_JUGES_DATA, payload: res.data.dbJuges }) })
            .catch((err) => console.log(err));
    };
};

// Edit juges
export const editJuges = (id, data) => {
    return (dispatch) => {
        return api
            .put(`/juges/${id}`, data)
            .then((res) => { dispatch({ type: EDIT_JUGES_DATA, payload: res.data.dbJuges }) })
            .catch((err) => console.log(err));
    };
}