/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import { GET_IMAGEINSCRIPTION_DATA, ADD_IMAGEINSCRIPTION_DATA, DELETE_IMAGEINSCRIPTION_DATA } from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get ImageInscription
export const getImageInscription = () => {
    return (dispatch) => {
        return api
            .get("/imageinscription/")
            .then((res) => { dispatch({ type: GET_IMAGEINSCRIPTION_DATA, payload: res.data.dbImageInscription }) })
            .catch((err) => console.log(err));
    };
};

// Add ImageInscription
export const addImageInscription = (data) => {
    return (dispatch) => {
        return api
            .post("/imageinscription/", data, { headers: { "Content-Type": "multipart/form-data" } })
            .then((res) => { dispatch({ type: ADD_IMAGEINSCRIPTION_DATA, payload: res.data.dbImageInscription }) })
            .catch((err) => console.log(err));
    };
};

// DeleteOne ImageInscription
export const deleteImageInscription = (id) => {
    return (dispatch) => {
        return api
            .delete(`/imageinscription/${id}`)
            .then((res) => { dispatch({ type: DELETE_IMAGEINSCRIPTION_DATA, payload: res.data.dbImageInscription }) })
            .catch((err) => console.log(err));
    };
};