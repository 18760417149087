/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
    GET_LINK_DATA,
    ADD_LINK_DATA,
    DELETE_LINK_DATA,
    EDIT_LINK_DATA,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get link
export const getLink = () => {
    return (dispatch) => {
        return api
            .get("/link/")
            .then((res) => {
                dispatch({
                    type: GET_LINK_DATA,
                    payload: res.data.dbLink
                });
            })
            .catch((err) => console.log(err));
    };
};

// Add link
export const addLink = (data) => {
    return (dispatch) => {
        return api
            .post("/link/", data)
            .then((res) => {
                dispatch({
                    type: ADD_LINK_DATA,
                    payload: res.data.dbLink
                });
            })
            .catch((err) => console.log(err));
    };
};

// DeleteOne link
export const deleteLink = (id) => {
    return (dispatch) => {
        return api
            .delete(`/link/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_LINK_DATA,
                    payload: res.data.dbLink
                });
            })
            .catch((err) => console.log(err));
    };
};

// Edit link
export const editLink = (data) => {
    return (dispatch) => {
        return api
            .put(`/link/${data._id}`, data)
            .then((res) => {
                dispatch({
                    type: EDIT_LINK_DATA,
                    payload: res.data.dbLink
                });
            })
            .catch((err) => console.log(err));
    };
}