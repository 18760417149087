import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import ListItemIcon from '@mui/material/ListItemIcon';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';

import { useDispatch } from "react-redux";
import { getProcedures, addProcedures, deleteProcedures, editProcedures } from "../../../store/actions/ProceduresActions";
import { editLink } from '../../../store/actions/LinkActions';

function ProcedureRow({ value, dispatch }) {

    const [openDelete, setOpenDelete] = useState(false);
    const [OpenUpdateProcedure, setOpenUpdateProcedure] = useState(false);
    const [updateProcedure, setUpdateProcedure] = useState('');

    const handleClickOpenDeleteProcedure = () => {
        setOpenDelete(!openDelete);
        setOpenUpdateProcedure(false);
    }

    const handleClickOpenUpdateProcedure = () => {
        setOpenDelete(false);
        setOpenUpdateProcedure(!OpenUpdateProcedure);
    }

    const handleClickUpdateProcedure = async () => {
        const data = {
            _id: value._id,
            procedure: updateProcedure ? updateProcedure : value.procedure,
        };
        await dispatch(editProcedures(data));
        setOpenUpdateProcedure(false);
    }

    const handleClickDeleteProcedure = () => {
        dispatch(deleteProcedures(value._id));
        setOpenDelete(false);
    }

    return (
        <List>
            <Box>
                <ListItem sx={{ display: { xs: 'block', md: 'flex' } }}>
                    <Box sx={{ display: 'flex' }}>
                        <ListItemIcon sx={{ color: '#fff', minWidth: 35 }}>
                            <SendIcon />
                        </ListItemIcon>
                        <ListItemText primary={value.procedure} sx={{ minWidth: 280, maxWidth: 350 }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <ListItemButton
                            onClick={() => handleClickOpenUpdateProcedure()}
                            sx={{ width: 100 }}
                        >
                            <ListItemIcon sx={{ width: '49%', mx: 'auto' }} >
                                <CreateIcon sx={{ color: '#32CD32', mx: 'auto' }} />
                            </ListItemIcon>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => handleClickOpenDeleteProcedure()}
                            sx={{ width: 100 }}
                        >
                            <ListItemIcon sx={{ width: '49%', mx: 'auto' }}>
                                <DeleteIcon sx={{ color: '#ff0000', mx: 'auto' }} />
                            </ListItemIcon>
                        </ListItemButton>
                    </Box>
                </ListItem>
                {/* Delete procedure*/}
                <Collapse in={openDelete} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            component="div"
                            align='center'
                            sx={{ my: 3, color: '#fff' }}
                        >
                            Voulez vous vraiment supprimer cette procédures:
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleClickDeleteProcedure()}
                                sx={{ py: 2, width: { xs: 100, md: 150 } }}
                            >
                                Oui
                            </Button>
                            <Button
                                variant="contained"
                                color="info"
                                onClick={() => setOpenDelete(false)}
                                sx={{ py: 2, width: { xs: 100, md: 150 } }}
                            >
                                Non
                            </Button>
                        </Box>
                    </Box>
                </Collapse>
                {/* Edit */}
                <Collapse in={OpenUpdateProcedure} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1, maxWidth: 400, mx: 'auto' }}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            component="div"
                            align='center'
                            sx={{ my: 3 }}
                        >
                            Mise a jour de la procedure: <br />
                        </Typography>
                        <TextField
                            label="Modification procédure"
                            variant="outlined"
                            fullWidth multiline
                            defaultValue={value.procedure}
                            onChange={(e) => setUpdateProcedure(e.target.value)}
                            InputLabelProps={{ style: { color: '#000' } }}
                            InputProps={{ style: { backgroundColor: '#fff' } }}
                            sx={{ mb: 5 }}
                        />
                        <Box
                            sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 5 }}
                        >
                            <Button
                                variant="contained"
                                color="info"
                                onClick={() => handleClickUpdateProcedure()}
                                sx={{ py: 2, width: { xs: 100, md: 150 } }}
                            >
                                Valider
                            </Button>
                            <Button
                                variant="contained"
                                color="info"
                                onClick={() => setOpenUpdateProcedure(false)}
                                sx={{ py: 2, width: { xs: 100, md: 150 } }}
                            >
                                Fermer
                            </Button>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        </List>
    )
}

export default function Procedures({ procedure, link }) {

    const dispatch = useDispatch('');
    const [openDocuments, setOpenDocuments] = useState(false);
    const [openLink, setOpenLink] = useState(false);
    const [updateLink, setUpdateLink] = useState(false);
    const [addDocument, setAddDocument] = useState('');

    const handleClickOpenDocuments = () => {
        setOpenDocuments(!openDocuments);
        setOpenLink(false);
    }

    const handleClickOpenLink = () => {
        setOpenLink(!openLink);
        setOpenDocuments(false);
    }

    const handleClickAddProcedure = async (e) => {
        if (addDocument.length > 0) {
            await dispatch(addProcedures({ addDocument }));
            setOpenDocuments(false);
            setAddDocument('')
        }
    }

    const handleClickUpdateLink = async () => {
        const data = {
            _id: link[0]._id,
            link: updateLink ? updateLink : link[0].link,
        }
        await dispatch(editLink(data));
        setOpenLink(false);
    }

    useEffect(() => {
        dispatch(getProcedures());
    }, [dispatch, addDocument]);


    return (
        <Card sx={{ mb: 5 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant='h5' gutterBottom align='center'>
                    Procédures
                </Typography>
                <Typography sx={{ mb: 1.5 }} variant='h6' align='center'>
                    Documents à faire parvenir
                </Typography>
                {procedure.map((value, index) => (
                    <ProcedureRow key={index} value={value} dispatch={dispatch} />
                ))}
                <CardActions>
                    <Button
                        sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }}
                        variant="contained"
                        size="small"
                        onClick={() => handleClickOpenDocuments()}
                        fullWidth
                    >
                        Ajouter un moyen de faire parvenir les documents
                    </Button>
                </CardActions>
                <Collapse in={openDocuments} timeout="auto" unmountOnExit>
                    <Box sx={{ mx: 2, mt: 1, mb: 5 }}>
                        <TextField
                            label="Ajouter une procedure pour faire parvenir un document"
                            variant="outlined"
                            fullWidth multiline
                            onChange={(e) => setAddDocument(e.target.value)}
                            InputLabelProps={{ style: { color: '#000' } }}
                            InputProps={{ style: { backgroundColor: '#fff' } }}
                            sx={{ mb: 5 }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                            <Button
                                variant="contained"
                                color="info"
                                onClick={() => handleClickAddProcedure()}
                                sx={{ py: 2, width: { xs: 100, md: 150 } }}
                            >
                                Valider
                            </Button>
                            <Button
                                variant="contained"
                                color="info"
                                onClick={() => setOpenDocuments(false)}
                                sx={{ py: 2, width: { xs: 100, md: 150 } }}
                            >
                                Fermer
                            </Button>
                        </Box>
                    </Box>
                </Collapse>

                <Typography sx={{ mb: 1.5 }} variant='body1' align='center'>
                    Lien Gest Gym
                </Typography>
                {link.length > 0 && (
                    <Typography variant='body2' align='center'>
                        {link[0].link}
                    </Typography>
                )}
                <CardActions>
                    <Button
                        sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }}
                        variant="contained"
                        size="small"
                        onClick={() => handleClickOpenLink()}
                        fullWidth
                    >
                        Editer le lien
                    </Button>
                </CardActions>
                <Collapse in={openLink} timeout="auto" unmountOnExit>
                    <Box sx={{ mx: 2, mt: 1, mb: 5 }}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            component="div"
                            align='center'
                            sx={{ my: 3 }}
                        >
                            Mise a jour du lien: <br />
                        </Typography>
                        <TextField
                            label="Modification Lien"
                            variant="outlined"
                            fullWidth multiline
                            onChange={(e) => setUpdateLink(e.target.value)}
                            InputLabelProps={{ style: { color: '#000' } }}
                            InputProps={{ style: { backgroundColor: '#fff' } }}
                            sx={{ mb: 5 }}
                        />
                        <Box
                            sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 5 }}
                        >
                            <Button
                                variant="contained"
                                color="info"
                                onClick={() => handleClickUpdateLink()}
                                sx={{ py: 2, width: { xs: 100, md: 150 } }}
                            >
                                Editer
                            </Button>
                            <Button
                                variant="contained"
                                color="info"
                                onClick={() => setOpenLink(false)}
                                sx={{ py: 2, width: { xs: 100, md: 150 } }}
                            >
                                Fermer
                            </Button>
                        </Box>
                    </Box>
                </Collapse>
            </CardContent>
        </Card >
    );
}
