/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
    GET_ACTIVITES_DATA,
    ADD_ACTIVITES_DATA,
    DELETE_ACTIVITES_DATA,
    EDIT_ACTIVITES_DATA,
    ADD_ACTIVITES_IMAGES_DATA,
    DELETE_ACTIVITES_IMAGES_DATA
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get Activités
export const getActivites = () => {
    return (dispatch) => {
        return api
            .get("/activites/")
            .then((res) => { dispatch({ type: GET_ACTIVITES_DATA, payload: res.data.dbActivites }) })
            .catch((err) => console.log(err));
    };
};

// Add Activités
export const addActivites = (data) => {
    return (dispatch) => {
        return api
            .post("/activites/", data)
            .then((res) => { dispatch({ type: ADD_ACTIVITES_DATA, payload: res.data.dbActivites }) })
            .catch((err) => console.log(err));
    };
};

// DeleteOne Activités
export const deleteActivites = (id) => {
    return (dispatch) => {
        return api
            .delete(`/activites/${id}`)
            .then((res) => { dispatch({ type: DELETE_ACTIVITES_DATA, payload: res.data.dbActivites }) })
            .catch((err) => console.log(err));
    };
};

// Edit Activités
export const editActivites = (id, data) => {
    return (dispatch) => {
        return api
            .put(`/activites/${id}`, data)
            .then((res) => { dispatch({ type: EDIT_ACTIVITES_DATA, payload: res.data.dbActivites }) })
            .catch((err) => console.log(err));
    };
}

// Add Images Activités
export const addActivitesImages = (id, data) => {
    return (dispatch) => {
        return api
            .post(`/activitesimages/${id}`, data)
            .then((res) => { dispatch({ type: ADD_ACTIVITES_IMAGES_DATA, payload: res.data.dbActivitesImages }) })
            .catch((err) => console.log(err));
    }
}

// Delete Images Activités
export const deleteActivitesImages = (id) => {
    return (dispatch) => {
        return api
            .delete(`/activitesimages/${id}`)
            .then((res) => { dispatch({ type: DELETE_ACTIVITES_IMAGES_DATA, payload: res.data.dbActivitesImages }) })
            .catch((err) => console.log(err));
    }
}