import React, { useState } from 'react';
import { BsFillArrowUpSquareFill } from 'react-icons/bs';
// import styled from 'styled-components';
import Button from '@mui/material/Button';

// export const Button = styled.div`
//    position: fixed; 
//    width: 100%;
//    left: 95%;
//    bottom: 70px;
//    height: 40px;
//    font-size: 3rem;
//    cursor: pointer;
//    color: #5c5c5c;
//    z-index: 1000;
// `;

const ScrollButton = () => {

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) { setVisible(true) }
    else if (scrolled <= 300) { setVisible(false) }
  };

  const scrollToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }) };

  window.addEventListener('scroll', toggleVisible);

  return (
    <Button
      sx={{ position: 'fixed', width: '100%', bottom: '70px', left: { md: '40%' }, height: '40px', fontSize: {xs: '3rem',md: '4rem'}, cursor: 'pointer', color: '#5c5c5c', zIndex: '1000' }}
    >
      <BsFillArrowUpSquareFill onClick={scrollToTop}
        style={{ display: visible ? 'inline' : 'none' }} />
    </Button >
  );
}

export default ScrollButton;