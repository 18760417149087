/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";


/*
 * Import types { ... }
 * ******************** */
import {
    GET_YEARSINSCRIPTION_DATA,
    ADD_YEARSINSCRIPTION_DATA,
    EDIT_YEARSINSCRIPTION_DATA,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get tarifs
export const getYears = () => {
    return (dispatch) => {
        return api
            .get("/years/")
            .then((res) => {
                dispatch({
                    type: GET_YEARSINSCRIPTION_DATA,
                    payload: res.data.dbYears
                });
            })
            .catch((err) => console.log(err));
    };
};

// Add tarifs
export const addYears = (data) => {
    return (dispatch) => {
        return api
            .post("/years/", data)
            .then((res) => {
                dispatch({
                    type: ADD_YEARSINSCRIPTION_DATA,
                    payload: res.data.dbYears
                });
            })
            .catch((err) => console.log(err));
    };
};

// Edit tarifs
export const editYears = (data) => {
    return (dispatch) => {
      return api
        .put(`/years/${data._id}`, data)
        .then((res) => {
          dispatch({
            type: EDIT_YEARSINSCRIPTION_DATA,
            payload: res.data.dbYears
          });
        })
        .catch((err) => console.log(err));
    };
  }