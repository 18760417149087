import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { editYears } from '../../../store/actions/YearsInscriptionActions';

function YearsDialog(props) {

    const { years, handleCloseYears, openYears, dispatch } = props;
    const [year, setYear] = useState(years[0].annee);
    const handleSubmitYear = async (e) => {
        const data = {
            _id: years[0]._id,
            annee: year ? year : years[0].annee,
        }
        await dispatch(editYears(data))
        handleCloseYears()
    }

    return (
        <Dialog onClose={handleCloseYears} open={openYears} PaperProps={{ style: { backgroundColor: '#fff' } }}>
            <DialogTitle sx={{ color: '#000', textDecoration: 'none' }}>
                Modifier l'année de la saion
            </DialogTitle>
            <TextField label="Année" variant="outlined" defaultValue={year} fullWidth
                onChange={(e) => setYear(e.target.value)}
                InputLabelProps={{ style: { color: '#000' } }}
                sx={{ mb: 5, mx: 'auto', width: '80%' }}
            />
            <Box
                sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                <Button variant="contained" color="info" onClick={() => handleSubmitYear()} sx={{ py: 2, width: 100 }} >
                    Valider
                </Button>
                <Button variant="contained" color="info" onClick={handleCloseYears} sx={{ py: 2, width: 100 }} >
                    Fermer
                </Button>
            </Box>
        </Dialog>
    )
}

export default function SaisonYears({ years, dispatch }) {

    const [openYears, setOpenYears] = useState(false);

    const handleClickOpenYears = () => { setOpenYears(true) }
    const handleCloseYears = () => { setOpenYears(false) }
    return (
        <Card sx={{ mb: 5 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant='h5' gutterBottom align='center'>
                    Saison
                </Typography>
                {years.map((value, index) => (
                    <Box key={index}>
                        <Typography variant='h5' align='center' sx={{ fontWeight: 'bold' }}>
                            {value.annee}
                        </Typography>
                        <CardActions>
                            <Button
                                sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }}
                                variant="contained"
                                size="small"
                                onClick={() => handleClickOpenYears()}
                                fullWidth
                            >
                                Editer
                            </Button>
                        </CardActions>
                        <YearsDialog
                            years={years}
                            openYears={openYears}
                            handleCloseYears={handleCloseYears}
                            dispatch={dispatch}
                        />
                    </Box>
                ))}
            </CardContent>
        </Card >
    );
}
