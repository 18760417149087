import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { store } from "./store";
import App from './App';
import { StyledEngineProvider } from "@mui/material/styles";
import './assets/css/index.scss';
import "typeface-pacifico";
import "typeface-permanent-marker";
import reportWebVitals from "./tests/reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <App />
    </Provider>
  </StyledEngineProvider>,
);

reportWebVitals();