import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { useSelector } from "react-redux";
import { getHistoireClub, editHistoireClub } from '../../../store/actions/HistoireClubActions';

function HistoireClubDialog(props) {

    const { HistoireClub, handleCloseHistoireClub, openHistoireClub, dispatch } = props;
    const [histoireClub, setHistoireClub] = useState('');

    const handleSubmitHistoireClub = async (e) => {
        const data = {
            _id: HistoireClub[0]._id,
            histoire: histoireClub ? histoireClub : HistoireClub[0].histoire,
        }
        await dispatch(editHistoireClub(data))
        handleCloseHistoireClub()
    }

    useEffect(() => { dispatch(getHistoireClub()) }, [dispatch]);

    return (
        <Dialog onClose={handleCloseHistoireClub} open={openHistoireClub} PaperProps={{ style: { backgroundColor: '#fff', maxWidth: '800px', width: '90%' } }}>
            <DialogTitle sx={{ color: '#000', textDecoration: 'none' }}>
                Modifier l'histoire du club
            </DialogTitle>
            <TextField label="histoire" variant="outlined" defaultValue={histoireClub} fullWidth multiline
                onChange={(e) => setHistoireClub(e.target.value)}
                InputLabelProps={{ style: { color: '#000' } }}
                sx={{ mb: 5, mx: 'auto', width: '80%' }}
            />
            <Box
                sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                <Button variant="contained" color="info" onClick={() => handleSubmitHistoireClub()} sx={{ py: 2, width: 100 }}>
                    Valider
                </Button>
                <Button variant="contained" color="info" onClick={handleCloseHistoireClub} sx={{ py: 2, width: 100 }}>
                    Fermer
                </Button>
            </Box>
        </Dialog>
    )
}

export default function HistoireClubCard({ dispatch }) {

    const [openHistoireClub, setOpenHistoireClub] = useState(false);
    const HistoireClub = useSelector((state) => state.histoireClub.histoireClubData);

    useEffect(() => { dispatch(getHistoireClub()) }, [dispatch]);

    const handleClickOpenHistoireClub = () => { setOpenHistoireClub(true) }
    const handleCloseHistoireClub = () => { setOpenHistoireClub(false) }
    return (
        <Card sx={{ mb: 5 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant='h5' gutterBottom align='center'>
                    Histoire du club
                </Typography>
                {HistoireClub.length > 0 && (
                    <Typography variant='body1' align='center' sx={{ color: '#fff' }}>
                        {HistoireClub[0].histoire}
                    </Typography>
                )}
            </CardContent>
            <CardActions>
                <Button sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }} variant="contained" size="small" fullWidth
                    onClick={() => handleClickOpenHistoireClub()}
                >
                    Editer
                </Button>
            </CardActions>
            <HistoireClubDialog
                HistoireClub={HistoireClub}
                openHistoireClub={openHistoireClub}
                handleCloseHistoireClub={handleCloseHistoireClub}
                dispatch={dispatch}
            />
        </Card >
    );
}