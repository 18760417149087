/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
    GET_ENTRAINEURS_DATA,
    ADD_ENTRAINEURS_DATA,
    DELETE_ENTRAINEURS_DATA,
    EDIT_ENTRAINEURS_DATA,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get Entraineur
export const getEntraineurs = () => {
    return (dispatch) => {
        return api
            .get("/entraineurs/")
            .then((res) => { dispatch({ type: GET_ENTRAINEURS_DATA, payload: res.data.dbEntraineur }) })
            .catch((err) => console.log(err));
    };
};

// Add Entraineur
export const addEntraineurs = (data) => {
    return (dispatch) => {
        return api
            .post("/entraineurs/", data)
            .then((res) => { dispatch({ type: ADD_ENTRAINEURS_DATA, payload: res.data.dbEntraineur }) })
            .catch((err) => console.log(err));
    };
};

// DeleteOne Entraineur
export const deleteEntraineurs = (id) => {
    return (dispatch) => {
        return api
            .delete(`/entraineurs/${id}`)
            .then((res) => { dispatch({ type: DELETE_ENTRAINEURS_DATA, payload: res.data.dbEntraineur }) })
            .catch((err) => console.log(err));
    };
};

// Edit Entraineur
export const editEntraineurs = (id, data) => {
    return (dispatch) => {
        return api
            .put(`/entraineurs/${id}`, data)
            .then((res) => { dispatch({ type: EDIT_ENTRAINEURS_DATA, payload: res.data.dbEntraineur }) })
            .catch((err) => console.log(err));
    };
}