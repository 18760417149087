import React, { useState, useEffect } from 'react';
import MainLayout from '../layouts/MainLayout';
import CssBaseline from "@mui/material/CssBaseline";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Footer from '../components/Footer';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';
import { urlImg } from '../utils/url';
import Dialog from '@mui/material/Dialog';

import { useSelector, useDispatch } from "react-redux";
import { getStore } from '../store/actions/BoutiqueActions';

function ArticleCard({ step, open, onClose }) {

    return (
        <Dialog onClose={onClose} open={open}
            sx={{
                m: 'auto',
                boxShadow: '5px 5px 15px rgba(0,0,0,0.5)',
                borderRadius: '10px'
            }}
        >
            <Box sx={{ bgcolor: '#fff' }}>
                <CardMedia component="img" image={`${urlImg + step.image}`} alt={step.name} sx={{ width: '100%', mt: '47px' }} />
            </Box>
            <Box className='product' sx={{ height: '100%', background: '#fff', pl: 5 }}>
                <Typography variant="h2"
                    sx={{
                        fontSize: { xs: '1.5em', md: '3em' },
                        color: '#000', mt: '-5px', textAlign: 'left'
                    }}>{step.titre}</Typography>
                <Typography variant="h3"
                    sx={{
                        fontSize: { xs: '1.5em', md: '3em' },
                        color: '#C3A1A0', mt: '-5px', textAlign: 'left'
                    }}>{step.prix}€</Typography>
                <Typography variant='body1' className='desc'
                    sx={{
                        fontSize: { xs: '1em', md: '1.3em' },
                        color: '#000',
                        mb: '17px',
                        lineHeight: '1.6em',
                        mr: '25px'
                    }}
                >
                    {step.desc}
                </Typography>
            </Box>
        </Dialog>
    )
}

function ArticleCards({ step }) {
    const [open, setOpen] = useState(false)
    const handleOpenArticle = () => { setOpen(!open) };
    const handleClose = () => { setOpen(false); };
    return (
        <Box
            sx={{
                m: ' 5px',
                width: { xs: '200px', md: '250px', lg: '24%' },
                maxWidth: "300px",
            }}
        >
            <Button
                sx={{ width: '100%', bgcolor: "white" }}
                onClick={() => handleOpenArticle()}
            >
                <CardMedia component="img" image={`${urlImg + step.image}`} alt={step.name}
                    sx={{ width: { xs: '150px', md: '200px', lg: '100%' } }} />
            </Button>
            <Button
                sx={{ color: 'black', display: 'block' }}
                onClick={() => handleOpenArticle()}
            >
                <Typography variant="body1" sx={{ mt: 2, textAlign: 'left' }}>{step.titre}</Typography>
                <Typography variant="body1" sx={{ my: 4, fontWeight: 'bolder', textAlign: 'left' }}>{step.prix}€</Typography>
            </Button>

            <ArticleCard open={open} step={step} onClose={handleClose} setOpen={setOpen} />
        </Box>
    )
}

export default function Boutique() {
    const dispatch = useDispatch();
    const store = useSelector((state) => state.boutique.boutiqueData);

    useEffect(() => { dispatch(getStore()) }, [dispatch]);
    return (
        <MainLayout>
            <CssBaseline />

            {/* Titre */}
            <Box component="div" sx={{ pt: 5, padding: '85px 16px 24px' }}>
                <Typography variant='h2' align='center' sx={{ fontSize: { xs: "2.5em", md: "5em" }, my: 2 }} >
                    Boutique
                </ Typography>
            </Box>

            <Box component="div"
                sx={{
                    padding: { xs: '85px 16px 250px', sm: '85px 16px 100px', md: '85px 16px 24px' }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-around',
                        bgcolor: '#e9e9e9',
                        maxWidth: '1300px',
                        mx: 'auto',
                        p: 10,
                        borderRadius: "2px"
                    }}
                >
                    {store.map((step, index) => (
                        <ArticleCards key={index} step={step} />
                    ))}
                </Box>
            </Box>

            {/* Footer */}
            <Footer />

        </MainLayout >
    );
};