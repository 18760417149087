/*
 * Import Actions { ... }
 * ********************** */
import * as Actions from "../actions/ActionsTypes";

/*
 * Selector
 * ******** */
const initialState = {
  userData: [],
};

/*
 * Reducers
 * ******** */
export function UserReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_USER_DATA:
      return {
        ...state, userData: action.payload
      };
    case Actions.ADD_USER_DATA:
      return {
        ...state, userData: action.payload
      };
    case Actions.DELETE_USER_DATA:
      return {
        ...state, userData: action.payload, 
      };
      case Actions.EDIT_USER_DATA:
        return {
          ...state, userData: action.payload, 
        };
    default:
      return state;
  }
}

/*
 * Getters
 * ******* */
