import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';

import AdminLayout from '../layouts/AdminLayout';
import AuthAdmin from '../components/AuthAdmin';

import { useSelector, useDispatch } from "react-redux";
import { getCalendrier } from '../store/actions/CalendrierActions';
import { getStore } from '../store/actions/BoutiqueActions';
import CalendrierCard from '../components/admin/admin/CalendrierCard';
import StoreCard from '../components/admin/admin/StoreCard';

const Admin = () => {
  const dispatch = useDispatch();
  const calendrier = useSelector((state) => state.calendrier.calendrierData);
  const store = useSelector((state) => state.boutique.boutiqueData);
  useEffect(() => {
    dispatch(getCalendrier());
    dispatch(getStore());
  }, [dispatch]);

  return (
    <AdminLayout>
      <Typography variant="h2" color="initial" align='center' sx={{ fontSize: { xs: "2em", md: "4em" }, my: 5 }} >
        Bienvenue Administrateur
      </Typography>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'baseline', justifyContent: 'space-around' }}>

        {/* Calendrier */}
        <CalendrierCard calendrier={calendrier} dispatch={dispatch} />

        {/* Boutique */}
        <StoreCard store={store} dispatch={dispatch} />
      </Box>
    </AdminLayout>
  );
};

export default AuthAdmin(Admin);