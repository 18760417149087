import React, { useEffect } from 'react';
import AdminLayout from '../layouts/AdminLayout';
import Typography from '@mui/material/Typography'
import Procedures from '../components/admin/adminInscription/Procedures';
import Box from '@mui/material/Box';
import Tarifs from '../components/admin/adminInscription/Tarifs';

import { useSelector, useDispatch } from "react-redux";
import { getTarifs } from '../store/actions/TarifsActions';
import { getProcedures } from '../store/actions/ProceduresActions';
import { getYears } from '../store/actions/YearsInscriptionActions';
import { getLink } from '../store/actions/LinkActions';
import SaisonYears from '../components/admin/adminInscription/SaisonYears';
import AuthAdmin from '../components/AuthAdmin';
import ImageInscription from '../components/admin/adminInscription/ImageInscription';

const AdminInscription = () => {
  const dispatch = useDispatch();
  const tarif = useSelector((state) => state.tarifs.tarifsData);
  const procedure = useSelector((state) => state.procedures.proceduresData);
  const years = useSelector((state) => state.years.yearsData);
  const link = useSelector((state) => state.link.linkData);

  useEffect(() => {
    dispatch(getTarifs());
    dispatch(getProcedures());
    dispatch(getYears());
    dispatch(getLink());
  }, [dispatch]);

  return (
    <AdminLayout>
      <Typography variant="h2" color="initial" align='center' sx={{ fontSize: { xs: "2em", md: "6em" } }} >
        Gestion des inscriptions
      </Typography>
      <Box maxWidth='xl' sx={{ m: 'auto', display: { xs: 'block', xl: 'flex' } }}>
        <Box sx={{ minWidth: 325, maxWidth: { xs: 350, md: 600 }, width: { xs: '90%', xl: '48%' }, my: 5, mx: { xs: 'auto', xl: 5 } }}>
          <SaisonYears years={years} dispatch={dispatch} />
          <Procedures procedure={procedure} link={link} dispatch={dispatch} />
          <ImageInscription dispatch={dispatch} />
        </Box>
        <Tarifs tarif={tarif} dispatch={dispatch} />
      </Box>
    </AdminLayout>
  );
};

export default AuthAdmin(AdminInscription);