import { createTheme } from '@mui/material/styles';

// Main Layout
let themeMain = createTheme({
    palette: {
        primary: {
            main: '#2d353c',
        },
        background: {
            paper: '#2d353c',
        },
        text: {
            primary: '#ffffff',
        },
        buttonRejoignez: {
            main: '#4040C0',
        },
        cardHistoire: {
            paper: 'rgba(0, 0, 0, 0)',
        }
    },
    typography: {
        h1: {
            fontFamily: "Pacifico, cursive",
            textAlign: 'center',
        },
        h2: {
            fontFamily: "Pacifico, cursive",
            textAlign: 'center',
        },
        h3: {
            fontFamily: "Pacifico, cursive",
            textAlign: 'center',
        },
        h6: {
            fontFamily: "Pacifico, cursive",
        },
    }
});

// AdminLayout
let themeAdmin = createTheme({
    palette: {
        primary: {
            main: '#2d353c',
        },
        background: {
            paper: '#2d353c',
        },
    },
    typography: {
        h2: {
            display: 'block'
        },
        h5: {
            color: '#fff'
        },
        h6: {
            color: '#fff',
            textDecoration: "underline"
        },
        body1: {
            color: '#fff'
        },
        body2: {
            color: '#fff'
        },
    }
});;

export { themeMain, themeAdmin }