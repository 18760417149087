/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
    GET_CALENDRIER_DATA,
    ADD_CALENDRIER_DATA,
    DELETE_CALENDRIER_DATA,
    EDIT_CALENDRIER_DATA,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get Calendrier
export const getCalendrier = () => {
    return (dispatch) => {
        return api
            .get("/calendrier/")
            .then((res) => { dispatch({ type: GET_CALENDRIER_DATA, payload: res.data.dbCalendrier }) })
            .catch((err) => console.log(err));
    };
};

// Add Calendrier
export const addCalendrier = (data) => {
    return (dispatch) => {
        return api
            .post("/calendrier/", data)
            .then((res) => { dispatch({ type: ADD_CALENDRIER_DATA, payload: res.data.dbCalendrier }) })
            .catch((err) => console.log(err));
    };
};

// DeleteOne Calendrier
export const deleteCalendrier = (id) => {
    return (dispatch) => {
        return api
            .delete(`/calendrier/${id}`)
            .then((res) => { dispatch({ type: DELETE_CALENDRIER_DATA, payload: res.data.dbCalendrier }) })
            .catch((err) => console.log(err));
    };
};

// Edit Calendrier
export const editCalendrier = (id, data) => {
    return (dispatch) => {
        return api
            .put(`/calendrier/${id}`, data)
            .then((res) => { dispatch({ type: EDIT_CALENDRIER_DATA, payload: res.data.dbCalendrier }) })
            .catch((err) => console.log(err));
    };
}