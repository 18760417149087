import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import CardActions from '@mui/material/CardActions';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'

import { getCalendrier, addCalendrier, deleteCalendrier, editCalendrier } from '../../../store/actions/CalendrierActions';

function DateRow(props) {
    const { value, dispatch } = props;
    const strDateDebut = value.dateDebut;
    const strDateFin = value.dateFin;
    const dd1 = strDateDebut.substring(0, 2);
    const mm1 = strDateDebut.substring(3, 5);
    const yyyy1 = strDateDebut.substring(6, 10);
    const dd2 = strDateFin.substring(0, 2);
    const mm2 = strDateFin.substring(3, 5);
    const yyyy2 = strDateFin.substring(6, 10);
    const [openDeleteDate, setOpenDeleteDate] = useState(false);
    const [openUpdateDate, setOpenUpdateDate] = useState(false);
    const [title, setTitle] = useState(value.titre);
    const [types, setTypes] = useState(value.type);
    const [days1, setDays1] = useState(dd1);
    const [month1, setMonth1] = useState(mm1);
    const [years1, setYears1] = useState(yyyy1);
    const [daysEnd2, setDaysEnd2] = useState(dd2);
    const [monthEnd2, setMonthEnd2] = useState(mm2);
    const [yearsEnd2, setYearsEnd2] = useState(yyyy2);
    const handleOpenDeleteDate = () => {
        setOpenDeleteDate(!openDeleteDate);
        setOpenUpdateDate(false);
    }
    const handleOpenUpdateDate = () => {
        setOpenDeleteDate(false);
        setOpenUpdateDate(!openUpdateDate);
    }
    const handleClickDeleteDate = (e) => {
        dispatch(deleteCalendrier(value._id));
        setOpenDeleteDate(false)
    }
    // const handleChangeType = (e) => { setTypeAdd(e.target.value) };
    const handleChangeDayDateDebut1 = (e) => { setDays1(e.target.value) };
    const handleChangeMonthDateDebut1 = (e) => { setMonth1(e.target.value) };
    const handleChangeDayDateFin2 = (e) => { setDaysEnd2(e.target.value) };
    const handleChangeMonthDateFin2 = (e) => { setMonthEnd2(e.target.value) };

    const handleClickUpdateDate = async () => {
        if (daysEnd2.length > 0 && monthEnd2.length > 0 && yearsEnd2.length > 0) {
            const data = {
                id: value._id,
                titre: title ? title : value.titre,
                type: types ? types : value.type,
                dateDebut: days1 + '/' + month1 + '/' + years1,
                dateFin: daysEnd2 + '/' + monthEnd2 + '/' + yearsEnd2
            }
            await dispatch(editCalendrier(value._id, data));
            setOpenUpdateDate(false);
        } else {
            const data = {
                id: value._id,
                titre: title ? title : value.titre,
                type: types ? types : value.type,
                dateDebut: days1 + '/' + month1 + '/' + years1,
                dateFin: ""
            }
            await dispatch(editCalendrier(value._id, data));
            setOpenUpdateDate(false);
        }
    }

    useEffect(() => { dispatch(getCalendrier()) }, [openDeleteDate, title, types, openUpdateDate, dispatch]);

    return (
        <React.Fragment>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{value.titre}</TableCell>
                <TableCell>{value.type}</TableCell>
                <TableCell>{value.dateDebut}</TableCell>
                <TableCell>{value.dateFin}</TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenUpdateDate()}><CreateIcon sx={{ color: '#000' }} /></Button>
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenDeleteDate()}><DeleteIcon sx={{ color: '#ff0000' }} /></Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>

                    {/* Delete */}
                    <Collapse in={openDeleteDate} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ my: 3, color: '#000' }}>
                                Voulez vous vraiment supprimer cette date: <br />
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                                <Button variant="contained" color="error" sx={{ py: 2, width: 150 }} onClick={() => handleClickDeleteDate()}>
                                    Oui
                                </Button>
                                <Button variant="contained" color="info" sx={{ py: 2, width: 150 }} onClick={() => setOpenDeleteDate(false)}>
                                    Non
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>

                    {/* Edit */}
                    <Collapse in={openUpdateDate} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, minWidth: 400, maxWidth: 600, mx: 'auto' }}>
                            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ my: 3, color: '#000' }}>
                                Mise a jour de la date
                            </Typography>
                            <TextField label="Titre" variant="outlined" fullWidth defaultValue={title}
                                onChange={(e) => setTitle(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }} sx={{ mb: 5 }}
                            />
                            <TextField label="Type" variant="outlined" fullWidth defaultValue={types}
                                onChange={(e) => setTypes(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }} sx={{ mb: 5 }}
                            />
                            {/* Date de début */}
                            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ color: '#fff' }}>
                                Date de début:
                            </Typography>
                            <Box sx={{ my: '5px', display: 'flex', justifyContent: 'space-between' }}>
                                <FormControl sx={{ width: "30%" }}>
                                    <InputLabel sx={{ color: 'black' }} id="demo-simple-select-helper-labelDayDebut">Jours</InputLabel>
                                    <Select
                                        value={days1 ?? " "}
                                        labelId="demo-simple-select-helper-labelDayDebut"
                                        id="demo-simple-select-helper" defaultValue={days1}
                                        label="Jours" inputProps={{ style: { backgroundColor: '#fff' } }}
                                        onChange={handleChangeDayDateDebut1}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <MenuItem value={'01'}>01</MenuItem>
                                        <MenuItem value={'02'}>02</MenuItem>
                                        <MenuItem value={'03'}>03</MenuItem>
                                        <MenuItem value={'04'}>04</MenuItem>
                                        <MenuItem value={'05'}>05</MenuItem>
                                        <MenuItem value={'06'}>06</MenuItem>
                                        <MenuItem value={'07'}>07</MenuItem>
                                        <MenuItem value={'08'}>08</MenuItem>
                                        <MenuItem value={'09'}>09</MenuItem>
                                        <MenuItem value={'10'}>10</MenuItem>
                                        <MenuItem value={'11'}>11</MenuItem>
                                        <MenuItem value={'12'}>12</MenuItem>
                                        <MenuItem value={'13'}>13</MenuItem>
                                        <MenuItem value={'14'}>14</MenuItem>
                                        <MenuItem value={'15'}>15</MenuItem>
                                        <MenuItem value={'16'}>16</MenuItem>
                                        <MenuItem value={'17'}>17</MenuItem>
                                        <MenuItem value={'18'}>18</MenuItem>
                                        <MenuItem value={'19'}>19</MenuItem>
                                        <MenuItem value={'20'}>20</MenuItem>
                                        <MenuItem value={'21'}>21</MenuItem>
                                        <MenuItem value={'22'}>22</MenuItem>
                                        <MenuItem value={'23'}>23</MenuItem>
                                        <MenuItem value={'24'}>24</MenuItem>
                                        <MenuItem value={'25'}>25</MenuItem>
                                        <MenuItem value={'26'}>26</MenuItem>
                                        <MenuItem value={'27'}>27</MenuItem>
                                        <MenuItem value={'28'}>28</MenuItem>
                                        <MenuItem value={'29'}>29</MenuItem>
                                        <MenuItem value={'30'}>30</MenuItem>
                                        <MenuItem value={'31'}>31</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ width: "30%" }}>
                                    <InputLabel sx={{ color: 'black' }} id="demo-simple-select-helper-labelMoisDebut">Mois</InputLabel>
                                    <Select
                                        value={month1 ?? " "}
                                        labelId="demo-simple-select-helper-labelMoisDebut"
                                        id="demo-simple-select-helper" defaultValue={month1}
                                        label="Mois" inputProps={{ style: { backgroundColor: '#fff' } }}
                                        onChange={handleChangeMonthDateDebut1}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <MenuItem value={'01'}>01</MenuItem>
                                        <MenuItem value={'02'}>02</MenuItem>
                                        <MenuItem value={'03'}>03</MenuItem>
                                        <MenuItem value={'04'}>04</MenuItem>
                                        <MenuItem value={'05'}>05</MenuItem>
                                        <MenuItem value={'06'}>06</MenuItem>
                                        <MenuItem value={'07'}>07</MenuItem>
                                        <MenuItem value={'08'}>08</MenuItem>
                                        <MenuItem value={'09'}>09</MenuItem>
                                        <MenuItem value={'10'}>10</MenuItem>
                                        <MenuItem value={'11'}>11</MenuItem>
                                        <MenuItem value={'12'}>12</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ width: "30%" }}>
                                    <TextField label="Année" variant="outlined" fullWidth defaultValue={years1}
                                        onChange={(e) => setYears1(e.target.value)}
                                        InputLabelProps={{ style: { color: '#000' } }}
                                        inputProps={{ style: { backgroundColor: '#fff' } }}
                                    />
                                </FormControl>
                            </Box>

                            {/* Date de fin */}
                            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ color: '#fff' }}>
                                Date de fin:
                            </Typography>
                            <Box sx={{ my: '5px', display: 'flex', justifyContent: 'space-between' }}>
                                <FormControl sx={{ width: "30%" }}>
                                    <InputLabel sx={{ color: 'black' }} id="demo-simple-select-helper-labelDayFin">Jours</InputLabel>
                                    <Select
                                        value={daysEnd2 ?? " "}
                                        labelId="demo-simple-select-helper-labelDayFin"
                                        id="demo-simple-select-helper" defaultValue={daysEnd2}
                                        label="Jours" inputProps={{ style: { backgroundColor: '#fff' } }}
                                        onChange={handleChangeDayDateFin2}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <MenuItem value={'01'}>01</MenuItem>
                                        <MenuItem value={'02'}>02</MenuItem>
                                        <MenuItem value={'03'}>03</MenuItem>
                                        <MenuItem value={'04'}>04</MenuItem>
                                        <MenuItem value={'05'}>05</MenuItem>
                                        <MenuItem value={'06'}>06</MenuItem>
                                        <MenuItem value={'07'}>07</MenuItem>
                                        <MenuItem value={'08'}>08</MenuItem>
                                        <MenuItem value={'09'}>09</MenuItem>
                                        <MenuItem value={'10'}>10</MenuItem>
                                        <MenuItem value={'11'}>11</MenuItem>
                                        <MenuItem value={'12'}>12</MenuItem>
                                        <MenuItem value={'13'}>13</MenuItem>
                                        <MenuItem value={'14'}>14</MenuItem>
                                        <MenuItem value={'15'}>15</MenuItem>
                                        <MenuItem value={'16'}>16</MenuItem>
                                        <MenuItem value={'17'}>17</MenuItem>
                                        <MenuItem value={'18'}>18</MenuItem>
                                        <MenuItem value={'19'}>19</MenuItem>
                                        <MenuItem value={'20'}>20</MenuItem>
                                        <MenuItem value={'21'}>21</MenuItem>
                                        <MenuItem value={'22'}>22</MenuItem>
                                        <MenuItem value={'23'}>23</MenuItem>
                                        <MenuItem value={'24'}>24</MenuItem>
                                        <MenuItem value={'25'}>25</MenuItem>
                                        <MenuItem value={'26'}>26</MenuItem>
                                        <MenuItem value={'27'}>27</MenuItem>
                                        <MenuItem value={'28'}>28</MenuItem>
                                        <MenuItem value={'29'}>29</MenuItem>
                                        <MenuItem value={'30'}>30</MenuItem>
                                        <MenuItem value={'31'}>31</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ width: "30%" }}>
                                    <InputLabel sx={{ color: 'black' }} id="demo-simple-select-helper-labelMoisFin">Mois</InputLabel>
                                    <Select
                                        value={monthEnd2 ?? " "}
                                        labelId="demo-simple-select-helper-labelMoisFin"
                                        id="demo-simple-select-helper" defaultValue={monthEnd2}
                                        label="Mois" inputProps={{ style: { backgroundColor: '#fff' } }}
                                        onChange={handleChangeMonthDateFin2}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <MenuItem value={'01'}>01</MenuItem>
                                        <MenuItem value={'02'}>02</MenuItem>
                                        <MenuItem value={'03'}>03</MenuItem>
                                        <MenuItem value={'04'}>04</MenuItem>
                                        <MenuItem value={'05'}>05</MenuItem>
                                        <MenuItem value={'06'}>06</MenuItem>
                                        <MenuItem value={'07'}>07</MenuItem>
                                        <MenuItem value={'08'}>08</MenuItem>
                                        <MenuItem value={'09'}>09</MenuItem>
                                        <MenuItem value={'10'}>10</MenuItem>
                                        <MenuItem value={'11'}>11</MenuItem>
                                        <MenuItem value={'12'}>12</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ width: "30%" }}>
                                    <TextField label="Année" variant="outlined" fullWidth defaultValue={yearsEnd2}
                                        onChange={(e) => setYearsEnd2(e.target.value)}
                                        InputLabelProps={{ style: { color: '#000' } }}
                                        inputProps={{ style: { backgroundColor: '#fff' } }}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 5 }}>
                                <Button variant="contained" color="info" sx={{ py: 2, width: 150 }} onClick={() => handleClickUpdateDate()}>
                                    Valider
                                </Button>
                                <Button variant="contained" color="info" sx={{ py: 2, width: 150 }} onClick={() => setOpenUpdateDate(false)}>
                                    Fermer
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

function AddDateCollapse(props) {
    const { onClose, openAddDate, dispatch } = props;
    const [titreAdd, setTitreAdd] = useState('');
    const [typeAdd, setTypeAdd] = useState('');
    const [days, setDays] = useState('');
    const [month, setMonth] = useState('');
    const [years, setYears] = useState('');
    const [daysEnd, setDaysEnd] = useState('');
    const [monthEnd, setMonthEnd] = useState('');
    const [yearsEnd, setYearsEnd] = useState('');

    const handleCloseAddDate = () => { onClose(); };
    const handleChangeType = (e) => { setTypeAdd(e.target.value) };
    const handleChangeDayDateDebut = (e) => { setDays(e.target.value) };
    const handleChangeMonthDateDebut = (e) => { setMonth(e.target.value) };
    const handleChangeDayDateFin = (e) => { setDaysEnd(e.target.value) };
    const handleChangeMonthDateFin = (e) => { setMonthEnd(e.target.value) };
    const handleSubmitDate = async (e) => {
        if (titreAdd.length > 0 && typeAdd.length > 0 && days.length > 0 && month.length > 0 && years.length > 0) {
            if (daysEnd.length > 0 && monthEnd.length > 0 && yearsEnd.length > 0) {
                const data = {
                    titre: titreAdd,
                    type: typeAdd,
                    dateDebut: days + '/' + month + '/' + years,
                    dateFin: daysEnd + '/' + monthEnd + '/' + yearsEnd
                }
                await dispatch(addCalendrier(data));
                setTitreAdd('');
                setTypeAdd('');
                onClose();
            } else {
                const data = {
                    titre: titreAdd,
                    type: typeAdd,
                    dateDebut: days + '/' + month + '/' + years,
                    dateFin: ""
                }
                await dispatch(addCalendrier(data));
                setTitreAdd('');
                setTypeAdd('');
                onClose();
            }
        }
    }

    useEffect(() => { dispatch(getCalendrier()) }, [dispatch, titreAdd, typeAdd]);

    return (
        <Collapse in={openAddDate} timeout="auto" unmountOnExit>
            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ mb: 3, color: '#fff' }}>
                Ajouter une date:
            </Typography>
            <List sx={{ pt: 0, minWidth: 400, maxWidth: 600, mx: 'auto' }}>

                {/* Titre */}
                <FormControl sx={{ pt: 0, minWidth: 400, maxWidth: 600, mx: 'auto', width: '100%' }}>
                    <TextField label="Titre" variant="outlined" fullWidth sx={{ my: '5px' }}
                        InputLabelProps={{ style: { color: '#000' } }} inputProps={{ style: { backgroundColor: '#fff' } }}
                        onChange={(e) => setTitreAdd(e.target.value)}
                    />

                    {/* Select type */}
                    <FormControl sx={{ my: '5px' }}>
                        <InputLabel sx={{ color: 'black' }} id="demo-simple-select-helper-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper" defaultValue={""}
                            label="Type" inputProps={{ style: { backgroundColor: '#fff' } }}
                            onChange={handleChangeType}
                            sx={{ backgroundColor: 'white' }}
                        >
                            <MenuItem value={'Competition'}>Competition</MenuItem>
                            <MenuItem value={'Stage'}>Stage</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Date de début */}
                    <Typography variant="body1" gutterBottom component="div" align='center' sx={{ color: '#fff' }}>
                        Date de début:
                    </Typography>
                    <Box sx={{ my: '5px', display: 'flex', justifyContent: 'space-between' }}>
                        <FormControl sx={{ width: "30%" }}>
                            <InputLabel sx={{ color: 'black' }} id="demo-simple-select-helper-labelDayDebut">Jours</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-labelDayDebut"
                                id="demo-simple-select-helper" defaultValue={""}
                                label="Jours" inputProps={{ style: { backgroundColor: '#fff' } }}
                                onChange={handleChangeDayDateDebut}
                                sx={{ backgroundColor: 'white' }}
                            >
                                <MenuItem value={'01'}>01</MenuItem>
                                <MenuItem value={'02'}>02</MenuItem>
                                <MenuItem value={'03'}>03</MenuItem>
                                <MenuItem value={'04'}>04</MenuItem>
                                <MenuItem value={'05'}>05</MenuItem>
                                <MenuItem value={'06'}>06</MenuItem>
                                <MenuItem value={'07'}>07</MenuItem>
                                <MenuItem value={'08'}>08</MenuItem>
                                <MenuItem value={'09'}>09</MenuItem>
                                <MenuItem value={'10'}>10</MenuItem>
                                <MenuItem value={'11'}>11</MenuItem>
                                <MenuItem value={'12'}>12</MenuItem>
                                <MenuItem value={'13'}>13</MenuItem>
                                <MenuItem value={'14'}>14</MenuItem>
                                <MenuItem value={'15'}>15</MenuItem>
                                <MenuItem value={'16'}>16</MenuItem>
                                <MenuItem value={'17'}>17</MenuItem>
                                <MenuItem value={'18'}>18</MenuItem>
                                <MenuItem value={'19'}>19</MenuItem>
                                <MenuItem value={'20'}>20</MenuItem>
                                <MenuItem value={'21'}>21</MenuItem>
                                <MenuItem value={'22'}>22</MenuItem>
                                <MenuItem value={'23'}>23</MenuItem>
                                <MenuItem value={'24'}>24</MenuItem>
                                <MenuItem value={'25'}>25</MenuItem>
                                <MenuItem value={'26'}>26</MenuItem>
                                <MenuItem value={'27'}>27</MenuItem>
                                <MenuItem value={'28'}>28</MenuItem>
                                <MenuItem value={'29'}>29</MenuItem>
                                <MenuItem value={'30'}>30</MenuItem>
                                <MenuItem value={'31'}>31</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ width: "30%" }}>
                            <InputLabel sx={{ color: 'black' }} id="demo-simple-select-helper-labelMoisDebut">Mois</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-labelMoisDebut"
                                id="demo-simple-select-helper" defaultValue={""}
                                label="Mois" inputProps={{ style: { backgroundColor: '#fff' } }}
                                onChange={handleChangeMonthDateDebut}
                                sx={{ backgroundColor: 'white' }}
                            >
                                <MenuItem value={'01'}>01</MenuItem>
                                <MenuItem value={'02'}>02</MenuItem>
                                <MenuItem value={'03'}>03</MenuItem>
                                <MenuItem value={'04'}>04</MenuItem>
                                <MenuItem value={'05'}>05</MenuItem>
                                <MenuItem value={'06'}>06</MenuItem>
                                <MenuItem value={'07'}>07</MenuItem>
                                <MenuItem value={'08'}>08</MenuItem>
                                <MenuItem value={'09'}>09</MenuItem>
                                <MenuItem value={'10'}>10</MenuItem>
                                <MenuItem value={'11'}>11</MenuItem>
                                <MenuItem value={'12'}>12</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ width: "30%" }}>
                            <TextField label="Année" variant="outlined" fullWidth
                                onChange={(e) => setYears(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }}
                                inputProps={{ style: { backgroundColor: '#fff' } }}
                            />
                        </FormControl>
                    </Box>

                    {/* Date de fin */}
                    <Typography variant="body1" gutterBottom component="div" align='center' sx={{ color: '#fff' }}>
                        Date de fin:
                    </Typography>
                    <Box sx={{ my: '5px', display: 'flex', justifyContent: 'space-between' }}>
                        <FormControl sx={{ width: "30%" }}>
                            <InputLabel sx={{ color: 'black' }} id="demo-simple-select-helper-labelDayFin">Jours</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-labelDayFin"
                                id="demo-simple-select-helper" defaultValue={""}
                                label="Jours" inputProps={{ style: { backgroundColor: '#fff' } }}
                                onChange={handleChangeDayDateFin}
                                sx={{ backgroundColor: 'white' }}
                            >
                                <MenuItem value={'01'}>01</MenuItem>
                                <MenuItem value={'02'}>02</MenuItem>
                                <MenuItem value={'03'}>03</MenuItem>
                                <MenuItem value={'04'}>04</MenuItem>
                                <MenuItem value={'05'}>05</MenuItem>
                                <MenuItem value={'06'}>06</MenuItem>
                                <MenuItem value={'07'}>07</MenuItem>
                                <MenuItem value={'08'}>08</MenuItem>
                                <MenuItem value={'09'}>09</MenuItem>
                                <MenuItem value={'10'}>10</MenuItem>
                                <MenuItem value={'11'}>11</MenuItem>
                                <MenuItem value={'12'}>12</MenuItem>
                                <MenuItem value={'13'}>13</MenuItem>
                                <MenuItem value={'14'}>14</MenuItem>
                                <MenuItem value={'15'}>15</MenuItem>
                                <MenuItem value={'16'}>16</MenuItem>
                                <MenuItem value={'17'}>17</MenuItem>
                                <MenuItem value={'18'}>18</MenuItem>
                                <MenuItem value={'19'}>19</MenuItem>
                                <MenuItem value={'20'}>20</MenuItem>
                                <MenuItem value={'21'}>21</MenuItem>
                                <MenuItem value={'22'}>22</MenuItem>
                                <MenuItem value={'23'}>23</MenuItem>
                                <MenuItem value={'24'}>24</MenuItem>
                                <MenuItem value={'25'}>25</MenuItem>
                                <MenuItem value={'26'}>26</MenuItem>
                                <MenuItem value={'27'}>27</MenuItem>
                                <MenuItem value={'28'}>28</MenuItem>
                                <MenuItem value={'29'}>29</MenuItem>
                                <MenuItem value={'30'}>30</MenuItem>
                                <MenuItem value={'31'}>31</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ width: "30%" }}>
                            <InputLabel sx={{ color: 'black' }} id="demo-simple-select-helper-labelMoisFin">Mois</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-labelMoisFin"
                                id="demo-simple-select-helper" defaultValue={""}
                                label="Mois" inputProps={{ style: { backgroundColor: '#fff' } }}
                                onChange={handleChangeMonthDateFin}
                                sx={{ backgroundColor: 'white' }}
                            >
                                <MenuItem value={'01'}>01</MenuItem>
                                <MenuItem value={'02'}>02</MenuItem>
                                <MenuItem value={'03'}>03</MenuItem>
                                <MenuItem value={'04'}>04</MenuItem>
                                <MenuItem value={'05'}>05</MenuItem>
                                <MenuItem value={'06'}>06</MenuItem>
                                <MenuItem value={'07'}>07</MenuItem>
                                <MenuItem value={'08'}>08</MenuItem>
                                <MenuItem value={'09'}>09</MenuItem>
                                <MenuItem value={'10'}>10</MenuItem>
                                <MenuItem value={'11'}>11</MenuItem>
                                <MenuItem value={'12'}>12</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ width: "30%" }}>
                            <TextField label="Année" variant="outlined" fullWidth
                                onChange={(e) => setYearsEnd(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }}
                                inputProps={{ style: { backgroundColor: '#fff' } }}
                            />
                        </FormControl>
                    </Box>

                </FormControl>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                    <Button variant="contained" color="info" sx={{ py: 2, width: 100 }} onClick={() => handleSubmitDate()}>
                        Valider
                    </Button>
                    <Button variant="contained" color="info" sx={{ py: 2, width: 100 }} onClick={handleCloseAddDate}>
                        Fermer
                    </Button>
                </Box>
            </List>
        </Collapse >
    );
}

function CalendrierCard({ calendrier, dispatch }) {
    const [openAddDate, setOpenAddDate] = useState(false);
    const handleClickOpenAddDate = () => { setOpenAddDate(!openAddDate) };
    const handleCloseAddDate = () => { setOpenAddDate(false) }

    return (
        <Card sx={{ mb: 5, maxWidth: '800px' }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant='h5' gutterBottom align='center'>
                    Calendrier
                </Typography>
                <TableContainer component={Paper} sx={{ bgcolor: '#fff' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Titre</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Date de début</TableCell>
                                <TableCell>Date de fin</TableCell>
                                <TableCell>Editer</TableCell>
                                <TableCell>Supprimer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {calendrier.map((value, index) => (<DateRow key={index} value={value} dispatch={dispatch} />))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Button sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }} variant="contained" size="medium" fullWidth
                    onClick={() => handleClickOpenAddDate()}
                >
                    Ajouter
                </Button>
            </CardActions>
            <AddDateCollapse openAddDate={openAddDate} onClose={() => handleCloseAddDate()} dispatch={dispatch} />
        </Card >
    )
}

export default CalendrierCard
