/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import { GET_CHAMPIONS_DATA, ADD_CHAMPIONS_DATA, DELETE_CHAMPIONS_DATA } from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get Champions
export const getChampions = () => {
    return (dispatch) => {
        return api
            .get("/champions/")
            .then((res) => {
                dispatch({ type: GET_CHAMPIONS_DATA, payload: res.data.dbChampions });
            })
            .catch((err) => console.log(err));
    };
};

// Add Champions
export const addChampions = (data) => {
    return (dispatch) => {
        return api
            .post("/champions/", data, { headers: { "Content-Type": "multipart/form-data" } })
            .then((res) => {
                dispatch({ type: ADD_CHAMPIONS_DATA, payload: res.data.dbChampions });
            })
            .catch((err) => console.log(err));
    };
};

// DeleteOne Champions
export const deleteChampions = (id) => {
    return (dispatch) => {
        return api
            .delete(`/champions/${id}`)
            .then((res) => {
                dispatch({ type: DELETE_CHAMPIONS_DATA, payload: res.data.dbChampions });
            })
            .catch((err) => console.log(err));
    };
};