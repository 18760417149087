/*
 * Import Actions { ... }
 * ********************** */
import * as Actions from "../actions/ActionsTypes";

/*
 * Selector
 * ******** */
const initialState = {
    auth: {},
    flash: "",
    authenticate: null,
};

/*
 * Reducers
 * ******** */
export function AuthReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
        case Actions.LOGIN:
            return {
                ...state,
                flash: action.payload.flash,
                authenticate: action.payload.authenticate,
                auth: action.payload.token,
            };
    }
}

/*
 * Getters
 * ******* */