import React from 'react'
import { Box, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();
    const handleClickFacebook = () => { window.location.href = 'https://www.facebook.com/vsfgym' };
    const handleClickInstagram = () => { window.location.href = 'https://www.instagram.com/vsfgymnastique/?hl=fr' };

    return (
        <Box sx={{ bgcolor: '#292929', display: 'block' }}>
            {/* Adresse Desktop */}
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-around', py: 5 }}>
                <Box>
                    <Box display={'flex'} sx={{ pt: '30px' }}>
                        <LocationOnIcon sx={{ fontSize: { xs: '25px', md: '50px' } }} />
                        <Box display={'block'} sx={{ pl: 2 }}>
                            <Typography variant="h5" color="#fff" sx={{ display: 'inline-block', width: '100%', py: 0, fontWeight: 'bold' }}>
                                Adresse
                            </Typography>
                            <Typography variant="body1" color="#fff" sx={{ display: 'inline-block', width: '100%', fontSize: { xs: '0.8rem', md: '1rem' } }}>
                                Salle Yves Troboul <br />
                                Avenue du Général de Gaulle<br />
                                72400 LA FERTÉ BERNARD
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box display={'flex'} sx={{ pt: '30px' }}>
                        <LocalPhoneIcon sx={{ fontSize: { xs: '25px', md: '50px' } }} />
                        <Box display={'block'} sx={{ pl: 2 }}>
                            <Typography variant="h5" color="#fff" sx={{ display: 'inline-block', width: '100%', py: 0, fontWeight: 'bold' }}>
                                Telephone
                            </Typography>
                            <Typography variant="body1" color="#fff" sx={{ display: 'inline-block', width: '100%', fontSize: { xs: '0.8rem', md: '1rem' } }}>
                                06 52 16 48 43
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box display={'flex'} sx={{ pt: '30px' }}>
                        <MailIcon sx={{ fontSize: { xs: '25px', md: '50px' } }} />
                        <Box display={'block'} sx={{ pl: 2 }}>
                            <Typography variant="h5" color="#fff" sx={{ display: 'inline-block', width: '100%', py: 0, fontWeight: 'bold' }}>
                                Mail
                            </Typography>
                            <Link href="mailto:vsf.gymnastique@gmail.com" color="inherit" sx={{ display: 'inline-block', width: '100%', textDecoration: 'none', fontSize: { xs: '0.8rem', md: '1rem' } }}>
                                vsf.gymnastique@gmail.com
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Adresse Mobile */}
            <Box sx={{ display: { xs: 'block', sm: 'none' }, py: 5 }}>
                <Box>
                    <Box display={'flex'} sx={{ pt: '30px', justifyContent: 'center' }}>
                        <LocationOnIcon sx={{ fontSize: '25px' }} />
                        <Box display={'block'} sx={{ pl: 2, width: '260px' }}>
                            <Typography variant="h5" color="#fff" sx={{ display: 'inline-block', width: '100%', py: 0, fontWeight: 'bold' }}>
                                Adresse
                            </Typography>
                            <Typography variant="body1" color="#fff" sx={{ display: 'inline-block', width: '100%', fontSize: '0.8rem' }}>
                                Salle Yves Troboul <br />
                                Avenue du Général de Gaulle<br />
                                72400 LA FERTÉ BERNARD
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box display={'flex'} sx={{ pt: '30px', justifyContent: 'center' }}>
                        <LocalPhoneIcon sx={{ fontSize: '25px' }} />
                        <Box display={'block'} sx={{ pl: 2, width: '260px' }}>
                            <Typography variant="h5" color="#fff" sx={{ display: 'inline-block', width: '100%', py: 0, fontWeight: 'bold' }}>
                                Telephone
                            </Typography>
                            <Typography variant="body1" color="#fff" sx={{ display: 'inline-block', width: '100%', fontSize: '0.8rem' }}>
                                06 52 16 48 43
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box display={'flex'} sx={{ pt: '30px', justifyContent: 'center' }}>
                        <MailIcon sx={{ fontSize: '25px' }} />
                        <Box display={'block'} sx={{ pl: 2, width: '260px' }}>
                            <Typography variant="h5" color="#fff" sx={{ display: 'inline-block', width: '100%', py: 0, fontWeight: 'bold' }}>
                                Mail
                            </Typography>
                            <Link href="mailto:vsf.gymnastique@gmail.com" color="inherit" sx={{ display: 'inline-block', width: '100%', fontSize: '0.8rem', textDecoration: 'none' }}>
                                vsf.gymnastique@gmail.com
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* Icons reseaux sociaux */}
            <Box sx={{ width: '200px', m: 'auto' }}>
                <Box display={'flex'} sx={{ mr: '25px', justifyContent: 'space-around' }}>
                    <Button onClick={() => handleClickFacebook()} variant="h6" sx={{ minWidth: '40px', p: 0 }}>
                        <FacebookIcon alt="Logo-facebook" sx={{ fontSize: '50px' }} />
                    </Button>
                    <Button onClick={() => handleClickInstagram()} variant="h6" sx={{ minWidth: '40px', p: 0 }}>
                        <InstagramIcon alt="Logo-facebook" sx={{ fontSize: '50px' }} />
                    </Button>
                </Box>
            </Box>

            {/* Links */}
            <Box sx={{ textAlign: 'center', my: 2 }}>
                <Link onClick={() => navigate({ pathname: `/mentions-legales` })} sx={{ color: 'white' }}>Mentions légales</Link>
            </Box>

            {/* Copyright */}
            <Box sx={{ display: 'flex' }}>
                <Typography variant="body2" color="#fff" align='center' paddingY={2} sx={{ display: 'inline-block', width: '100%' }}>
                    copyright@2022 VSF Gymnastique
                </Typography>
            </Box>
        </Box >
    )
}

export default Footer;
