import React, { useEffect } from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import MainLayout from '../layouts/MainLayout';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Bureau from '../assets/aDINISTRATIF.jpg';
import Entraineur from '../assets/ENTRAINEUR.jpg';
import Juge from '../assets/Juge.jpg';
import FondHistoire from '../assets/dsg.jpg'
import ScrollButton from '../components/ScrollButton';
import Footer from '../components/Footer';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { EffectCoverflow, Pagination } from "swiper";
import SwiperCore, { Autoplay } from 'swiper';

import { useSelector, useDispatch } from "react-redux";
import { urlImg } from "../utils/url";
import { getEntraineurs } from '../store/actions/EntraineursActions';
import { getJuges } from '../store/actions/JugesActions';
import { getEquipeAdministrative } from '../store/actions/EquipeAdministrativeActions';
import { getChampions } from '../store/actions/ChampionsActions';
import { getSalle } from '../store/actions/SalleActions';
import { getHistoireClub } from '../store/actions/HistoireClubActions';

export default function Club() {

    const dispatch = useDispatch();
    const entraineur = useSelector((state) => state.entraineurs.entraineursData);
    const juge = useSelector((state) => state.juges.jugesData);
    const adminTeam = useSelector((state) => state.equipeAdministrative.teamAdministrativeData);
    const champions = useSelector((state) => state.champions.championsData);
    const salle = useSelector((state) => state.salle.salleData);
    const histoireClub = useSelector((state) => state.histoireClub.histoireClubData);

    SwiperCore.use([Autoplay])

    useEffect(() => {
        dispatch(getEntraineurs());
        dispatch(getJuges());
        dispatch(getEquipeAdministrative());
        dispatch(getChampions());
        dispatch(getSalle());
        dispatch(getHistoireClub());
    }, [dispatch]);

    return (
        <MainLayout>
            <CssBaseline />

            {/* Titre */}
            <Box component="div" sx={{ pt: 5, padding: '85px 16px 24px' }}>
                <Typography variant='h2' align='center' sx={{ fontSize: { xs: "2.5em", md: "5em" }, my: 2 }} >
                    Le club
                </ Typography>
            </Box>

            <Box component="div"
                sx={{
                    py: 5, my: "20px", mx: 'auto', minHeight: '1000px',
                    display: 'block', width: '100%', borderRadius: 1,
                    backgroundImage: `url(${FondHistoire})`, backgroundSize: 'cover',
                }}
            >
                {/* Histoire */}
                <Typography variant='h2' align='center'
                    sx={{ fontSize: { xs: "2rem", md: "4rem" }, py: { xs: 2, md: 10 }, color: '#fff' }} >
                    L'histoire du club
                </ Typography>
                {histoireClub.length > 0 && (
                    <Box sx={{ m: 2, boxShadow: 'none', backgroundColor: 'cardHistoire' }} >
                        <CardContent>
                            <Typography variant="h5" align='center'
                                sx={{ fontSize: { xs: "1rem", md: "1.3rem" }, color: 'white', px: { xs: 5, md: 15 } }} >
                                {histoireClub[0].histoire}
                            </Typography>
                        </CardContent>
                    </Box>
                )}
                {/* La salle */}
                <Box component="div" sx={{ py: 5, my: "20px", mx: 'auto', display: 'block', width: '90%', borderRadius: 1 }}>

                    {/* swiper */}
                    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                        <Swiper
                            loop={true}
                            effect={"coverflow"}
                            autoplay={{ delay: 2000 }}
                            slidesPerView={3}
                            spaceBetween={30}
                            pagination={{ clickable: true, }}
                            modules={[EffectCoverflow, Pagination]}
                            className="mySwiper"
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                        >
                            {salle.map((step, index) => (
                                <SwiperSlide key={index}>
                                    <img width="100%" src={`${urlImg + step.image}`} alt={step.name} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>

                    {/* Media query tablette */}
                    <Box sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}>
                        <Swiper
                            loop={true}
                            effect={"coverflow"}
                            autoplay={{ delay: 2000 }}
                            slidesPerView={2}
                            spaceBetween={30}
                            pagination={{ clickable: true, }}
                            modules={[EffectCoverflow, Pagination]}
                            className="mySwiper"
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                        >
                            {salle.map((step, index) => (
                                <SwiperSlide key={index}>
                                    <img width="100%" src={`${urlImg + step.image}`} alt={step.name} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                    {/* Media query mobile */}
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Swiper
                            loop={true}
                            effect={"coverflow"}
                            autoplay={{ delay: 2000 }}
                            slidesPerView={1}
                            spaceBetween={30}
                            pagination={{ clickable: true, }}
                            modules={[EffectCoverflow, Pagination]}
                            className="mySwiper"
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                        >
                            {salle.map((step, index) => (
                                <SwiperSlide key={index}>
                                    <img width="100%" src={`${urlImg + step.image}`} alt={step.name} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ p: "50px 0 0" }}>
                <Typography variant='h2' align='center' sx={{ fontSize: { xs: "2rem", md: "4rem" }, py: { xs: 2, md: 10 }, color: '#fff' }} >
                    Nos membres
                </ Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {/* L'équipe administrative */}
                    <Box component="div"
                        sx={{
                            py: 5, my: "20px", mx: '10px',
                            display: 'block', width: '80%', boxSizing: 'border-box',
                            minWidth: 400, maxWidth: 500, borderRadius: 1,
                        }}>
                        <Box component="div"
                            sx={{ height: '94%', width: '92%', maxWidth: '400px', mx: 'auto', position: 'relative', bgColor: 'white', color: '#fff', mb: '60px' }}>
                            <Box component="div"
                                sx={{
                                    width: '100%', height: '100%', position: 'absolute', p: "6px", border: '1px solid #fff', opacity: 0.5, left: '-6px', top: '-6px'
                                }}>
                            </Box>
                            <CardMedia component="img" image={Bureau} alt="Bureau" sx={{ width: '90%', position: 'absolute', top: '-6%', left: '-6%', zIndex: 5 }} />
                            <Box sx={{ padding: '150px 0px 30px 10px', position: 'relative', width: '96%', height: '98%', bgcolor: '#EB5160' }}>
                                <Typography variant='h2' align='center' sx={{ fontSize: { xs: "1.5em", md: "3.5em" }, py: 5, color: '#fff' }}>
                                    L'équipe administrative
                                </ Typography>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white', fontWeight: 'bold', border: 'none' }}>Prénom Nom</TableCell>
                                                <TableCell sx={{ color: 'white', fontWeight: 'bold', border: 'none' }} align="right">Poste</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {adminTeam.map((row, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: "none" } }} >
                                                    <TableCell component="th" scope="row" sx={{ color: 'white', border: 'none' }}>{row.prenom} {row.nom}</TableCell>
                                                    <TableCell sx={{ color: 'white', border: 'none' }} align="right">{row.poste}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>

                    {/* Entraineurs */}
                    <Box component="div"
                        sx={{
                            py: 5, my: "20px", mx: '10px',
                            display: 'block', width: '80%', boxSizing: 'border-box',
                            minWidth: 400, maxWidth: 500, borderRadius: 1,
                        }}
                    >
                        <Box component="div"
                            sx={{ height: '94%', width: '92%', maxWidth: '400px', mx: 'auto', position: 'relative', bgColor: 'white', color: '#fff', mb: '60px' }}>
                            <Box component="div"
                                sx={{
                                    width: '100%', height: '100%', position: 'absolute', p: "6px", border: '1px solid #fff', opacity: 0.5, left: '-6px', top: '-6px'
                                }}>
                            </Box>
                            <CardMedia component="img" image={Entraineur} alt="Entraineur" sx={{ width: '90%', position: 'absolute', top: '-6%', left: '-6%', zIndex: 5 }} />
                            <Box sx={{ padding: '150px 0px 30px 10px', position: 'relative', width: '96%', height: '98%', bgcolor: '#8F3985' }}>
                                <Typography variant='h2' align='center' sx={{ fontSize: { xs: "1.5em", md: "3.5em" }, py: 5, color: '#fff' }}>
                                    Nos entraineurs
                                </ Typography>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white', fontWeight: 'bold', border: 'none' }}>Prénom Nom</TableCell>
                                                <TableCell sx={{ color: 'white', fontWeight: 'bold', border: 'none' }} align="right">Diplome</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {entraineur.map((row, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell component="th" scope="row" sx={{ color: 'white', border: 'none' }}>{row.prenom} {row.nom}</TableCell>
                                                    <TableCell sx={{ color: 'white', border: 'none' }} align="right">{row.diplome}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>

                    {/* Juges */}
                    <Box component="div"
                        sx={{
                            py: 5, my: "20px", mx: '10px',
                            display: 'block', width: '80%', boxSizing: 'border-box',
                            minWidth: 400, maxWidth: 500, borderRadius: 1,
                        }}
                    >
                        <Box component="div"
                            sx={{ height: '94%', width: '92%', maxWidth: '400px', mx: 'auto', position: 'relative', bgColor: 'white', color: '#fff', mb: '60px' }}>
                            <Box component="div"
                                sx={{
                                    width: '100%', height: '100%', position: 'absolute', p: "6px", border: '1px solid #fff', opacity: 0.5, left: '-6px', top: '-6px'
                                }}>
                            </Box>
                            <CardMedia component="img" image={Juge} alt="Juge" sx={{ width: '90%', position: 'absolute', top: '-6%', left: '-6%', zIndex: 5 }} />
                            <Box sx={{ padding: '150px 0px 30px 10px', position: 'relative', width: '96%', height: '98%', bgcolor: '#8DAA91' }}>
                                <Typography variant='h2' align='center' sx={{ fontSize: { xs: "1.5em", md: "3.5em" }, py: 5, color: '#fff' }}>
                                    Nos Juges
                                </ Typography>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white', fontWeight: 'bold', border: 'none' }}>Prénom Nom</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {juge.map((row, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell component="th" scope="row" sx={{ color: 'white', border: 'none' }} >{row.prenom} {row.nom}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* Nos champions */}
            <Box component="div" sx={{ py: 5, mt: "20px", mx: 'auto', bgcolor: 'black', display: 'block', width: '100%', borderRadius: 1, minHeight: '1000px' }}>
                <Typography variant='h2' align='center' sx={{ fontSize: { xs: "2.5em", md: "5em" }, mb: 5, mt: 2, color: 'white' }} >
                    Nos champions
                </ Typography>
                <Box sx={{ width: '90%', mx: 'auto', display: { xs: 'none', md: 'block' } }}>
                    <ImageList variant="masonry" cols={4} gap={8}>
                        {champions.map((item) => (
                            <ImageListItem key={item.name}>
                                <img
                                    src={`${urlImg + item.image}?w=248&fit=crop&auto=format`}
                                    srcSet={`${urlImg + item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.name}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>

                <Box sx={{ width: '90%', mx: 'auto', display: { xs: 'block', md: 'none' } }}>
                    <ImageList variant="masonry" cols={2} gap={8}>
                        {champions.map((item) => (
                            <ImageListItem key={item.name}>
                                <img
                                    src={`${urlImg + item.image}?w=248&fit=crop&auto=format`}
                                    srcSet={`${urlImg + item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.name}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>

            </Box>

            <ScrollButton />

            {/* Footer */}
            <Footer />

        </MainLayout >
    );
};