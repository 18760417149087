import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from '@mui/material/GlobalStyles';
import { themeAdmin } from "../config/theme";
import { style } from '../config/globalStyle'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import RowingIcon from '@mui/icons-material/Rowing';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupsIcon from '@mui/icons-material/Groups';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Drawer from '@mui/material/Drawer';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        minHeight: '100vh',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function ModalDialog(props) {
    const navigate = useNavigate();
    const { openModalConfirmation, setOpenModalConfirmation } = props;
    const handleCloseModal = () => { setOpenModalConfirmation(false) };
    const logout = () => { localStorage.removeItem("user_token") };
    const handleLogOut = () => {
        logout();
        setTimeout(() => { navigate("/"); window.location.reload() }, 200)
    }

    return (
        <Dialog onClose={handleCloseModal} open={openModalConfirmation} PaperProps={{ style: { backgroundColor: '#fff' } }}>
            <DialogTitle sx={{ color: '#000', textDecoration: 'none' }}>
                Voulez-vous vous déconnecter?
            </DialogTitle>
            <Box
                sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                <Button variant="contained" color="info" sx={{ py: 2, width: 100 }} onClick={handleLogOut} >
                    Oui
                </Button>
                <Button variant="contained" color="info" sx={{ py: 2, width: 100 }} onClick={handleCloseModal} >
                    Non
                </Button>
            </Box>
        </Dialog>
    )
};

export default function AdminLayout({ children }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openDrawerMobile, setOpenDrawerMobile] = useState(false);
    const handleDrawerOpen = () => { setOpen(true) };
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
    const handleDrawerMobileOpen = () => { setOpenDrawerMobile(!openDrawerMobile); };
    const handleDrawerClose = () => { setOpen(false); };
    const handleDrawerMobileClose = () => { setOpenDrawerMobile(false); };
    const handleClickOpenModalConfirmation = () => { setOpenModalConfirmation(true) };

    const MenuList = [
        { titre: 'Accueil', icon: <HomeIcon />, link: '/admin' },
        { titre: 'Le club', icon: <GroupsIcon />, link: '/admin/club' },
        { titre: 'Inscription', icon: <AssignmentIndIcon />, link: '/admin/inscription' },
        { titre: 'Nos activités', icon: <RowingIcon />, link: '/admin/activites' },
    ]

    return (
        <ThemeProvider theme={themeAdmin}>
            <CssBaseline />
            <GlobalStyles styles={{ ...style }} />
            <Box sx={{ backgroundColor: '#dee2e6', display: 'flex', }}>
                <CssBaseline />

                {/* AppBar */}
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start"
                            sx={{ display: { xs: "none", md: "block" }, mr: 2, ...(open && { display: 'none' }) }} >
                            <MenuIcon />
                        </IconButton>
                        <IconButton
                            color="inherit" aria-label="open drawer" onClick={handleDrawerMobileOpen} edge="start"
                            sx={{ display: { xs: "block", md: "none" }, mr: 2, ...(open && { display: 'none' }) }} >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">VSF GYMNASTIQUE </Typography>
                    </Toolbar>
                </AppBar>

                {/* Drawer */}
                <Drawer
                    sx={{ width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' } }}
                    variant="persistent" anchor="left" open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose} sx={{ color: 'white' }}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {MenuList.map((value, index) => (
                            <ListItem button key={index} onClick={() => navigate({ pathname: `${value.link}` })} >
                                <ListItemIcon sx={{ color: '#fff' }}>
                                    {value.icon}
                                </ListItemIcon>
                                <ListItemText primary={value.titre} />
                            </ListItem>
                        ))}
                        <ListItem button onClick={handleClickOpenModalConfirmation} >
                            <ListItemIcon sx={{ color: '#fff' }}>
                                <LinkOffIcon />
                            </ListItemIcon>
                            <ListItemText primary='Déconnexion' />
                        </ListItem>
                    </List>
                </Drawer>

                {/* Drawer mobile */}
                <SwipeableDrawer anchor='bottom' open={openDrawerMobile}
                    onClose={() => handleDrawerMobileClose()}
                    onOpen={() => handleDrawerMobileOpen()}
                    PaperProps={{ style: { backgroundColor: '#fff' } }}
                >
                    <Box>
                        <List>
                            {MenuList.map((text, index) => (
                                <ListItem button key={index} onClick={() => navigate({ pathname: `${text.link}` })}>
                                    <ListItemIcon>
                                        {text.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={text.titre}
                                        primaryTypographyProps={{ style: { color: '#000' } }} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </SwipeableDrawer>

                < ModalDialog openModalConfirmation={openModalConfirmation} setOpenModalConfirmation={setOpenModalConfirmation} />

                <Main open={open}>
                    <DrawerHeader />
                    {children}
                </Main>
            </Box>
        </ThemeProvider >
    );
};