import React, { useEffect } from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Typography } from "@mui/material";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CardMedia from "@mui/material/CardMedia";
import CommuneSportive from "../assets/logo commune la plus sportive.jpg";
import Flammes from "../assets/Panneau Label 2021 - 5 Flammes.png";
import LFB from '../assets/Logo LFB Vecto 2018 sans fond.png';
import FFGYM from '../assets/LOGO_FFGYM-removebg-preview.png';
import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';

import MainLayout from '../layouts/MainLayout';
import Footer from '../components/Footer';

import { useSelector, useDispatch } from "react-redux";
import { getCalendrier } from '../store/actions/CalendrierActions';

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calendrier = useSelector((state) => state.calendrier.calendrierData);
  const ImageLogo = [
    { titre: CommuneSportive, link: "Logo Commune la plus sportive" },
    { titre: Flammes, link: "Logo 5 flammes" },
    { titre: LFB, link: 'LFB' },
    { titre: FFGYM, link: 'FFGYM' },
  ]

  useEffect(() => { dispatch(getCalendrier()) }, [dispatch]);

  return (
    <MainLayout>
      <Card sx={{ background: 'none', boxShadow: 'none', display: { xs: 'block', md: 'flex' }, justifyContent: 'space-around', textAlign: 'center' }}>
        {/* Logo liste */}
        <Box sx={{ padding: { xs: '70px 0 0 10px', md: '180px 0 30px 10px' }, display: 'block', justifyContent: { xs: 'space-around', md: 'none' } }}>
          {ImageLogo.map((value, index) => (
            <CardMedia key={index} component="img" image={value.titre} alt={value.link}
              sx={{ width: { xs: 75, sm: 100, lg: 200 }, py: '15px', display: { xs: 'inline-block', md: 'block' }, m: { xs: 'auto 10px', md: 0 } }} />
          ))}
        </Box>

        {/* Titre */}
        <CardContent>
          <Box sx={{ padding: { xs: '40px 16px 30px', md: '140px 16px 100px' } }}>
            <Typography sx={{ color: '#fff', my: 2, fontSize: { xs: "3em", md: "6em" } }} variant='h1' align='center' >
              VSF Gymnastique
            </Typography>
          </Box>
          <Box sx={{ my: 3 }}>
            <Typography variant='h3' sx={{ fontSize: { xs: "1.5em", md: "3em" }, my: 2 }}>
              Salle Yves Troboul
            </Typography>
            <Typography variant='h3' sx={{ fontSize: { xs: "1.5em", md: "3em" }, my: 2 }}>
              Avenue du Général de Gaulle
            </Typography>
            <Typography variant='h3' sx={{ fontSize: { xs: "1.5em", md: "3em" }, my: 2 }}>
              72400 LA FERTÉ BERNARD
            </Typography>
          </Box>
          {/* Bouton rejoignez nous */}
          <Stack sx={{ width: '300px', margin: { xs: '60px auto 0', md: '60px auto 150px' } }}>
            <Button
              color='buttonRejoignez' variant="contained" className="ButtonInscrisToi"
              onClick={() => navigate({ pathname: '/inscription' })}
              sx={{ fontSize: { xs: "1em", md: "1.5em" }, padding: '10px', overflow: 'hidden' }}
            >
              <span className='spanButtonIInscrisToi' >Rejoignez nous</span>
            </Button>
          </Stack>
        </CardContent>

        {/* Calendrier */}
        <Box sx={{ m: { xs: ' 100px auto', md: '180px 10px 30px' }, width: { sm: '100%', md: '380px' } }}>
          <Box
            sx={{
              p: '0 0 20px',
              borderRadius: { xs: 'none', md: "10px" },
              backgroundColor: '#fff',
              boxShadow: '0px 10px 30px -10px #000',
              overflow: 'hidden'
            }}
          >
            <Box className='mobile'
              sx={{
                background: 'linear-gradient(to left, #ff0000, rgba(255, 0, 0, .25))',
                color: '#FFF',
                padding: '37px 40px',
                boxShadow: '0px 0px 40px -9px #ff0000',
                mb: '50px',
              }}
            >
              <Typography variant='h3' sx={{ fontSize: { xs: "1.5em", md: "2em" }, my: 2 }}>
                Calendrier
              </Typography>
            </Box>
            <Box className="upcoming-events">
              <Box sx={{ width: '90%', mx: 'auto' }}>
                <Typography variant='h4' sx={{ color: 'black', fontSize: '17px', mb: '30px', position: 'relative', textAlign: 'left' }}>
                  Prochains événements
                </Typography>
              </Box>
            </Box>
            {calendrier.map((value, index) => (
              <List key={index} sx={{ mb: '30px', pb: 0 }}>
                <Box sx={{ px: '30px', display: { xs: 'block', lg: 'flex' }, justifyContent: 'space-between' }}>
                  <Typography variant='h5' sx={{ fontSize: '15px', color: '#000', fontWeight: '800', letterSpacing: '1px', m: 0, textAlign: 'left' }}>
                    {value.dateDebut}
                  </Typography>
                  <Typography variant='h5' sx={{ color: '#3F4247', fontSize: '15px', textAlign: 'left', fontWeight: 'bold' }}>
                    {value.titre}
                  </Typography>
                </Box>
              </List>
            ))}
          </Box>
        </Box>
      </Card>

      {/* Footer */}
      <Footer />

    </MainLayout>
  );
};