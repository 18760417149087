import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { useSelector } from "react-redux";
import { addEquipeAdministrative, getEquipeAdministrative, deleteEquipeAdministrative, editEquipeAdministrative } from '../../../store/actions/EquipeAdministrativeActions'

function AdminTeamRow(props) {

    const { value, dispatch } = props;
    const [openDeleteAdminTeam, setOpenDeleteAdminTeam] = useState(false);
    const [openUpdateAdminTeam, setOpenUpdateAdminTeam] = useState(false);
    const [prenom, setPrenom] = useState(value.prenom);
    const [nom, setNom] = useState(value.nom);
    const [poste, setPoste] = useState(value.poste);

    const handleOpenDeleteAdminTeam = () => {
        setOpenDeleteAdminTeam(!openDeleteAdminTeam);
        setOpenUpdateAdminTeam(false);
    }

    const handleOpenUpdateAdminTeam = () => {
        setOpenDeleteAdminTeam(false);
        setOpenUpdateAdminTeam(!openUpdateAdminTeam);
    }

    const handleClickDeleteAdminTeam = (e) => {
        dispatch(deleteEquipeAdministrative(value._id));
        setOpenDeleteAdminTeam(false)
    }

    const handleClickUpdateAdminTeam = async () => {
        const data = { prenom, nom, poste }
        await dispatch(editEquipeAdministrative(value._id, data));
        setOpenUpdateAdminTeam(false);
    }

    useEffect(() => { dispatch(getEquipeAdministrative()) }, [openDeleteAdminTeam, poste, prenom, nom, openUpdateAdminTeam, dispatch]);

    return (
        <React.Fragment>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{value.prenom}</TableCell>
                <TableCell>{value.nom}</TableCell>
                <TableCell>{value.poste}</TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenUpdateAdminTeam()}><CreateIcon sx={{ color: '#000' }} /></Button>
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenDeleteAdminTeam()}><DeleteIcon sx={{ color: '#ff0000' }} /></Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>

                    {/* Delete */}
                    <Collapse in={openDeleteAdminTeam} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ my: 3, color: '#000' }}>
                                Voulez vous vraiment supprimer cet personne: <br />
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                                <Button variant="contained" color="error" sx={{ py: 2, width: 150 }} onClick={() => handleClickDeleteAdminTeam()}>
                                    Oui
                                </Button>
                                <Button variant="contained" color="info" sx={{ py: 2, width: 150 }} onClick={() => setOpenDeleteAdminTeam(false)}>
                                    Non
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>

                    {/* Edit */}
                    <Collapse in={openUpdateAdminTeam} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, minWidth: 400, maxWidth: 600, mx: 'auto' }}>
                            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ my: 3, color: '#000' }}>
                                Mise a jour de la personne
                            </Typography>
                            <TextField label="Prénom" variant="outlined" fullWidth defaultValue={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }} sx={{ mb: 5 }}
                            />
                            <TextField label="Nom" variant="outlined" fullWidth defaultValue={nom}
                                onChange={(e) => setNom(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }} sx={{ mb: 5 }}
                            />
                            <TextField label="Poste" variant="outlined" fullWidth defaultValue={poste}
                                onChange={(e) => setPoste(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }} sx={{ mb: 5 }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 5 }}>
                                <Button variant="contained" color="info" sx={{ py: 2, width: 150 }} onClick={() => handleClickUpdateAdminTeam()}>
                                    Valider
                                </Button>
                                <Button variant="contained" color="info" sx={{ py: 2, width: 150 }} onClick={() => setOpenUpdateAdminTeam(false)}>
                                    Fermer
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

function AddAdminTeamCollapse(props) {
    const { onClose, openAddAdminTeam, dispatch } = props;
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [poste, setPoste] = useState('');

    const handleCloseAddAdminTeam = () => { onClose(); };

    const handleSubmitAdminTeam = async (e) => {
        const dataFormAdminTeam = { nom, prenom, poste };
        if (nom.length > 0 && prenom.length > 0 && poste.length > 0) {
            await dispatch(addEquipeAdministrative(dataFormAdminTeam));
            setNom('');
            setPrenom('');
            setPoste('');
            onClose();
        }
    }

    useEffect(() => { dispatch(getEquipeAdministrative()) }, [dispatch, nom, prenom, poste]);

    return (
        <Collapse in={openAddAdminTeam} timeout="auto" unmountOnExit>
            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ mb: 3, color: '#fff' }}>
                Ajouter une personne:
            </Typography>
            <List sx={{ pt: 0, minWidth: 400, maxWidth: 600, mx: 'auto' }}>
                <ListItem>
                    <TextField label="Prénom" variant="outlined" fullWidth
                        InputLabelProps={{ style: { color: '#000' } }} inputProps={{ style: { backgroundColor: '#fff' } }}
                        onChange={(e) => setPrenom(e.target.value)}
                    />
                </ListItem>
                <ListItem>
                    <TextField label="Nom" variant="outlined" fullWidth
                        onChange={(e) => setNom(e.target.value)}
                        InputLabelProps={{ style: { color: '#000' } }}
                        inputProps={{ style: { backgroundColor: '#fff' } }}
                    />
                </ListItem>
                <ListItem>
                    <TextField label="Poste" variant="outlined" fullWidth
                        onChange={(e) => setPoste(e.target.value)}
                        InputLabelProps={{ style: { color: '#000' } }}
                        inputProps={{ style: { backgroundColor: '#fff' } }}
                    />
                </ListItem>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                    <Button variant="contained" color="info" sx={{ py: 2, width: 100 }} onClick={() => handleSubmitAdminTeam()}>
                        Valider
                    </Button>
                    <Button variant="contained" color="info" sx={{ py: 2, width: 100 }} onClick={handleCloseAddAdminTeam}>
                        Fermer
                    </Button>
                </Box>
            </List>
        </Collapse>
    );
}

export default function AdminTeamCard({ dispatch }) {
    const [openAddAdminTeam, setOpenAddAdminTeam] = useState(false);
    const adminTeam = useSelector((state) => state.equipeAdministrative.teamAdministrativeData);

    useEffect(() => { dispatch(getEquipeAdministrative()) }, [dispatch]);

    const handleClickOpenAddAdminTeam = () => { setOpenAddAdminTeam(!openAddAdminTeam) };
    const handleCloseAddAdminTeam = () => { setOpenAddAdminTeam(false); }

    return (
        <Card sx={{ my: 5 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant='h5' align='center' gutterBottom>
                    Equipe administrative
                </Typography>
                <TableContainer component={Paper} sx={{ bgcolor: '#fff' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Prénom</TableCell>
                                <TableCell>Nom</TableCell>
                                <TableCell>Poste</TableCell>
                                <TableCell>Editer</TableCell>
                                <TableCell>Supprimer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {adminTeam.map((value, index) => (<AdminTeamRow key={index} value={value} dispatch={dispatch} />))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Button sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }} variant="contained" size="medium" fullWidth
                    onClick={() => handleClickOpenAddAdminTeam()}
                >
                    Ajouter
                </Button>
            </CardActions>
            <AddAdminTeamCollapse openAddAdminTeam={openAddAdminTeam} onClose={() => handleCloseAddAdminTeam()} dispatch={dispatch} />
        </Card>
    );
}