/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
    GET_STORE_DATA,
    ADD_STORE_DATA,
    DELETE_STORE_DATA,
    EDIT_STORE_DATA,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get Store
export const getStore = () => {
    return (dispatch) => {
        return api
            .get("/store/")
            .then((res) => { dispatch({ type: GET_STORE_DATA, payload: res.data.dbBoutique }) })
            .catch((err) => console.log(err));
    };
};

// Add Store
export const addStore = (data) => {
    return (dispatch) => {
        return api
            .post("/store/", data, { headers: { "Content-Type": "multipart/form-data" } })
            .then((res) => { dispatch({ type: ADD_STORE_DATA, payload: res.data.dbBoutique }) })
            .catch((err) => console.log(err));
    };
};

// DeleteOne Store
export const deleteStore = (id) => {
    return (dispatch) => {
        return api
            .delete(`/store/${id}`)
            .then((res) => { dispatch({ type: DELETE_STORE_DATA, payload: res.data.dbBoutique }) })
            .catch((err) => console.log(err));
    };
};

// Edit Store
export const editStore = (id, data) => {
    return (dispatch) => {
        return api
            .put(`/store/${id}`, data, { headers: { "Content-Type": "multipart/form-data" } })
            .then((res) => { dispatch({ type: EDIT_STORE_DATA, payload: res.data.dbBoutique }) })
            .catch((err) => console.log(err));
    };
}