/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import { GET_SALLE_DATA, ADD_SALLE_DATA, DELETE_SALLE_DATA } from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get salle
export const getSalle = () => {
    return (dispatch) => {
        return api
            .get("/salle/")
            .then((res) => {
                dispatch({ type: GET_SALLE_DATA, payload: res.data.dbSalle });
            })
            .catch((err) => console.log(err));
    };
};

// Add salle
export const addSalle = (data) => {
    return (dispatch) => {
        return api
            .post("/salle/", data, { headers: { "Content-Type": "multipart/form-data" } })
            .then((res) => {
                dispatch({ type: ADD_SALLE_DATA, payload: res.data.dbSalle });
            })
            .catch((err) => console.log(err));
    };
};

// DeleteOne salle
export const deleteSalle = (id) => {
    return (dispatch) => {
        return api
            .delete(`/salle/${id}`)
            .then((res) => {
                dispatch({ type: DELETE_SALLE_DATA, payload: res.data.dbSalle });
            })
            .catch((err) => console.log(err));
    };
};