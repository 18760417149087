/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
    GET_HISTOIRECLUB_DATA,
    ADD_HISTOIRECLUB_DATA,
    DELETE_HISTOIRECLUB_DATA,
    EDIT_HISTOIRECLUB_DATA,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get HistoireClub
export const getHistoireClub = () => {
    return (dispatch) => {
        return api
            .get("/histoire/")
            .then((res) => {
                dispatch({
                    type: GET_HISTOIRECLUB_DATA,
                    payload: res.data.dbHistoireClub
                });
            })
            .catch((err) => console.log(err));
    };
};

// Add HistoireClub
export const addHistoireClub = (data) => {
    return (dispatch) => {
        return api
            .post("/histoire/", data)
            .then((res) => {
                dispatch({
                    type: ADD_HISTOIRECLUB_DATA,
                    payload: res.data.dbHistoireClub
                });
            })
            .catch((err) => console.log(err));
    };
};

// DeleteOne HistoireClub
export const deleteHistoireClub = (id) => {
    return (dispatch) => {
        return api
            .delete(`/histoire/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_HISTOIRECLUB_DATA,
                    payload: res.data.dbHistoireClub
                });
            })
            .catch((err) => console.log(err));
    };
};

// Edit HistoireClub
export const editHistoireClub = (data) => {
    return (dispatch) => {
        return api
            .put(`/histoire/${data._id}`, data)
            .then((res) => {
                dispatch({
                    type: EDIT_HISTOIRECLUB_DATA,
                    payload: res.data.dbHistoireClub
                });
            })
            .catch((err) => console.log(err));
    };
}