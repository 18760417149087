import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CardMedia from '@mui/material/CardMedia';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CreateIcon from '@mui/icons-material/Create';
import TableContainer from '@mui/material/TableContainer';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';

import { urlImg } from '../../../utils/url';

import { getStore, addStore, deleteStore, editStore } from '../../../store/actions/BoutiqueActions';


function StoreRow(props) {

    const { value, dispatch } = props;
    const [openDeleteStore, setOpenDeleteStore] = useState(false);
    const [openUpdateStore, setOpenUpdateStore] = useState(false);
    const [title, setTitle] = useState(value.titre);
    const [price, setPrice] = useState(value.prix);
    const [describe, setDescribe] = useState(value.desc);
    const [image, setImage] = useState({});

    const handleOpenDeleteStore = () => {
        setOpenDeleteStore(!openDeleteStore);
        setOpenUpdateStore(false);
    }

    const handleOpenUpdateStore = () => {
        setOpenDeleteStore(false);
        setOpenUpdateStore(!openUpdateStore);
    }

    const handleClickDeleteStore = (e) => {
        dispatch(deleteStore(value._id));
        setOpenDeleteStore(false)
    }
    const handleClickUpdateStore = async () => {
        if (image.size > 0) {
            const data = {
                titre: title,
                prix: price,
                desc: describe,
            };
            const formData = new FormData();
            Object.entries(data).forEach(([cle, valeur]) => {
                formData.append(cle, valeur);
            });
            formData.append('image', image)
            await dispatch(editStore(value._id, formData));
            setOpenUpdateStore(false);
        } else {
            const data = {
                titre: title,
                prix: price,
                desc: describe
            };
            await dispatch(editStore(value._id, data));
            setOpenUpdateStore(false);
        }

    }

    useEffect(() => {
        dispatch(getStore())
    }, [openDeleteStore, title, price, describe, openUpdateStore, dispatch]);

    return (
        <React.Fragment>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{value.titre}</TableCell>
                <TableCell>{value.prix}</TableCell>
                <TableCell>{value.desc}</TableCell>
                <TableCell>
                    <CardMedia component="img" image={`${urlImg + value.image}`} alt={value.name} sx={{ height: '100px' }} />
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenUpdateStore()}>
                        <CreateIcon sx={{ color: '#000' }} />
                    </Button>
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenDeleteStore()}>
                        <DeleteIcon sx={{ color: '#ff0000' }} />
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>

                    {/* Delete */}
                    <Collapse in={openDeleteStore} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                                align='center'
                                sx={{ my: 3, color: '#000' }}
                            >
                                Voulez vous vraiment supprimer cet article: <br />
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => handleClickDeleteStore()}
                                    sx={{ py: 2, width: 150 }}
                                >
                                    Oui
                                </Button>
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => setOpenDeleteStore(false)}
                                    sx={{ py: 2, width: 150 }}
                                >
                                    Non
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>

                    {/* Edit */}
                    <Collapse in={openUpdateStore} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, maxWidth: 400, mx: 'auto' }}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                                align='center'
                                sx={{ my: 3, color: '#000' }}
                            >
                                Mise a jour de l'article: <br />
                                {value.activite}
                            </Typography>
                            <TextField
                                label="Titre"
                                variant="outlined"
                                fullWidth
                                defaultValue={title}
                                onChange={(e) => setTitle(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }}
                                sx={{ mb: 5 }}
                            />
                            <TextField
                                label="Prix"
                                variant="outlined"
                                fullWidth
                                defaultValue={price}
                                onChange={(e) => setPrice(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }}
                                sx={{ mb: 5 }}
                            />
                            <TextField
                                label="Description"
                                variant="outlined"
                                fullWidth
                                defaultValue={describe}
                                onChange={(e) => setDescribe(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }}
                                sx={{ mb: 5 }}
                            />
                            <ListItem>
                                <TextField
                                    size="small"
                                    type="file"
                                    inputProps={{ accept: "image/*" }}
                                    onChange={(e) => setImage(e.target.files[0])}
                                />
                            </ListItem>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 5 }}>
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => handleClickUpdateStore()}
                                    sx={{ py: 2, width: 150 }}
                                >
                                    Valider
                                </Button>
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => setOpenUpdateStore(false)}
                                    sx={{ py: 2, width: 150 }}
                                >
                                    Fermer
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

function AddStoreCollapse(props) {
    const { onClose, openAddStore, dispatch } = props;
    const [titreAdd, setTitreAdd] = useState('');
    const [prixAdd, setPrixAdd] = useState('');
    const [descriptionAdd, setDescriptionAdd] = useState('');
    const [image, setImage] = useState({});

    const handleCloseAddStore = () => { onClose(); };

    const handleSubmitStore = async (e) => {
        if (titreAdd.length > 0 && prixAdd.length > 0 && descriptionAdd.length > 0 && image.name) {
            const data = {
                titre: titreAdd,
                prix: prixAdd,
                desc: descriptionAdd
            };
            const formData = new FormData();
            Object.entries(data).forEach(([cle, valeur]) => {
                formData.append(cle, valeur);
            });
            formData.append('image', image)
            await dispatch(addStore(formData));
            setTitreAdd('');
            setPrixAdd('');
            setDescriptionAdd('');
            setImage('');
            onClose();
        }
    }

    useEffect(() => { dispatch(getStore()) }, [dispatch, titreAdd, prixAdd, descriptionAdd, image]);

    return (
        <Collapse in={openAddStore} timeout="auto" unmountOnExit>
            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ mb: 3, color: '#fff' }}>
                Ajouter un article:
            </Typography>
            <List sx={{ pt: 0, minWidth: 400, maxWidth: 600, mx: 'auto' }}>

                {/* Titre */}
                <FormControl sx={{ pt: 0, minWidth: 400, maxWidth: 600, mx: 'auto', width: '100%' }}>
                    <TextField label="Titre" variant="outlined" fullWidth sx={{ my: '5px' }}
                        InputLabelProps={{ style: { color: '#000' } }} inputProps={{ style: { backgroundColor: '#fff' } }}
                        onChange={(e) => setTitreAdd(e.target.value)}
                    />
                    <TextField label="Prix" variant="outlined" fullWidth sx={{ my: '5px' }}
                        InputLabelProps={{ style: { color: '#000' } }} inputProps={{ style: { backgroundColor: '#fff' } }}
                        onChange={(e) => setPrixAdd(e.target.value)}
                    />
                    <TextField label="Description" variant="outlined" fullWidth sx={{ my: '5px' }}
                        InputLabelProps={{ style: { color: '#000' } }} inputProps={{ style: { backgroundColor: '#fff' } }}
                        onChange={(e) => setDescriptionAdd(e.target.value)}
                    />
                    <TextField size="small" type="file" inputProps={{ accept: "image/*" }} onChange={(e) => setImage(e.target.files[0])} />
                </FormControl>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                    <Button variant="contained" color="info" sx={{ py: 2, width: 100 }} onClick={() => handleSubmitStore()}>
                        Valider
                    </Button>
                    <Button variant="contained" color="info" sx={{ py: 2, width: 100 }} onClick={handleCloseAddStore}>
                        Fermer
                    </Button>
                </Box>
            </List>
        </Collapse >
    );
}

function StoreCard({ store, dispatch }) {

    const [openAddStore, setOpenAddStore] = useState(false);
    const handleClickOpenAddStore = () => { setOpenAddStore(!openAddStore) };
    const handleCloseAddStore = () => { setOpenAddStore(false) }
    return (
        <Card sx={{ mb: 5, maxWidth: '800px' }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant='h5' gutterBottom align='center'>
                    Boutique
                </Typography>
                <TableContainer component={Paper} sx={{ bgcolor: '#fff' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Titre</TableCell>
                                <TableCell>Prix (€)</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>Editer</TableCell>
                                <TableCell>Supprimer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {store.map((value, index) => (<StoreRow key={index} value={value} dispatch={dispatch} />))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Button sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }} variant="contained" size="medium" fullWidth
                    onClick={() => handleClickOpenAddStore()}
                >
                    Ajouter
                </Button>
            </CardActions>
            <AddStoreCollapse openAddStore={openAddStore} onClose={() => handleCloseAddStore()} dispatch={dispatch} />
        </Card >
    )
}

export default StoreCard
