/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
    GET_TARIFS_DATA,
    ADD_TARIFS_DATA,
    DELETE_TARIFS_DATA,
    EDIT_TARIFS_DATA,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get tarifs
export const getTarifs = () => {
    return (dispatch) => {
        return api
            .get("/tarifs/")
            .then((res) => {
                dispatch({
                    type: GET_TARIFS_DATA,
                    payload: res.data.dbTarifs
                });
            })
            .catch((err) => console.log(err));
    };
};

// Add tarifs
export const addTarifs = (data) => {
    return (dispatch) => {
        return api
            .post("/tarifs/", data)
            .then((res) => {
                dispatch({
                    type: ADD_TARIFS_DATA,
                    payload: res.data.dbTarifs
                });
            })
            .catch((err) => console.log(err));
    };
};

// DeleteOne tarifs
export const deleteTarifs = (id) => {
    return (dispatch) => {
        return api
            .delete(`/tarifs/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_TARIFS_DATA,
                    payload: res.data.dbTarifs
                });
            })
            .catch((err) => console.log(err));
    };
};

// Edit tarifs
export const editTarifs = (data) => {
    return (dispatch) => {
      return api
        .put(`/tarifs/${data._id}`, data)
        .then((res) => {
          dispatch({
            type: EDIT_TARIFS_DATA,
            payload: res.data.dbTarifs
          });
        })
        .catch((err) => console.log(err));
    };
  }