import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { useSelector } from "react-redux";
import { addEntraineurs, getEntraineurs, deleteEntraineurs, editEntraineurs } from '../../../store/actions/EntraineursActions';

function EntraineurRow(props) {

    const { value, dispatch } = props;
    const [openDeleteEntraineur, setOpenDeleteEntraineur] = useState(false);
    const [openUpdateEntraineur, setOpenUpdateEntraineur] = useState(false);
    const [prenom, setPrenom] = useState(value.prenom);
    const [nom, setNom] = useState(value.nom);
    const [diplome, setDiplome] = useState(value.diplome);

    const handleOpenDeleteEntraineur = () => {
        setOpenDeleteEntraineur(!openDeleteEntraineur);
        setOpenUpdateEntraineur(false);
    }

    const handleOpenUpdateEntraineur = () => {
        setOpenDeleteEntraineur(false);
        setOpenUpdateEntraineur(!openUpdateEntraineur);
    }

    const handleClickDeleteEntraineur = (e) => {
        dispatch(deleteEntraineurs(value._id));
        setOpenDeleteEntraineur(false)
    }

    const handleClickUpdateEntraineur = async () => {
        const data = { prenom, nom, diplome }
        await dispatch(editEntraineurs(value._id, data));
        setOpenUpdateEntraineur(false);
    }

    useEffect(() => { dispatch(getEntraineurs()) }, [openDeleteEntraineur, diplome, prenom, nom, openUpdateEntraineur, dispatch]);

    return (
        <React.Fragment>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{value.prenom}</TableCell>
                <TableCell>{value.nom}</TableCell>
                <TableCell>{value.diplome}</TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenUpdateEntraineur()}><CreateIcon sx={{ color: '#000' }} /></Button>
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenDeleteEntraineur()}><DeleteIcon sx={{ color: '#ff0000' }} /></Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>

                    {/* Delete */}
                    <Collapse in={openDeleteEntraineur} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ my: 3, color: '#000' }}>
                                Voulez vous vraiment supprimer cet entraineur: <br />
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                                <Button variant="contained" color="error" onClick={() => handleClickDeleteEntraineur()} sx={{ py: 2, width: 150 }}>
                                    Oui
                                </Button>
                                <Button variant="contained" color="info" onClick={() => setOpenDeleteEntraineur(false)} sx={{ py: 2, width: 150 }}>
                                    Non
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>

                    {/* Edit */}
                    <Collapse in={openUpdateEntraineur} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, minWidth: 400, maxWidth: 600, mx: 'auto' }}>
                            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ my: 3, color: '#000' }}>
                                Mise a jour de l'entraineur: <br />{value.prenom} {value.nom}
                            </Typography>
                            <TextField label="Prénom" variant="outlined" fullWidth defaultValue={prenom} sx={{ mb: 5 }}
                                onChange={(e) => setPrenom(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }}
                            />
                            <TextField label="Nom" variant="outlined" fullWidth defaultValue={nom} sx={{ mb: 5 }}
                                onChange={(e) => setNom(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }}
                            />
                            <TextField label="Diplome" variant="outlined" fullWidth defaultValue={diplome} sx={{ mb: 5 }}
                                onChange={(e) => setDiplome(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 5 }}>
                                <Button variant="contained" color="info" onClick={() => handleClickUpdateEntraineur()} sx={{ py: 2, width: 150 }}>
                                    Valider
                                </Button>
                                <Button variant="contained" color="info" onClick={() => setOpenUpdateEntraineur(false)} sx={{ py: 2, width: 150 }}>
                                    Fermer
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

function AddEntraineurCollapse(props) {
    const { onClose, openAddEntraineur, dispatch } = props;
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [diplome, setDiplome] = useState('');

    const handleCloseAddEntraineur = () => { onClose(); };

    const handleSubmitEntraineur = async (e) => {
        const dataFormEntraineur = { nom, prenom, diplome };
        if (nom.length > 0 && prenom.length > 0 && diplome.length > 0) {
            await dispatch(addEntraineurs(dataFormEntraineur));
            setNom('');
            setPrenom('');
            setDiplome('');
            onClose();
        }
    }

    useEffect(() => { dispatch(getEntraineurs()) }, [dispatch, nom, prenom, diplome]);

    return (
        <Collapse in={openAddEntraineur} timeout="auto" unmountOnExit>
            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ mb: 3, color: '#fff' }}>
                Ajouter un entraineur:
            </Typography>
            <List sx={{ pt: 0, minWidth: 400, maxWidth: 600, mx: 'auto' }}>
                <ListItem>
                    <TextField label="Prénom" variant="outlined" fullWidth onChange={(e) => setPrenom(e.target.value)}
                        InputLabelProps={{ style: { color: '#000' } }}
                        inputProps={{ style: { backgroundColor: '#fff' } }}
                    />
                </ListItem>
                <ListItem>
                    <TextField label="Nom" variant="outlined" fullWidth onChange={(e) => setNom(e.target.value)}
                        InputLabelProps={{ style: { color: '#000' } }}
                        inputProps={{ style: { backgroundColor: '#fff' } }}
                    />
                </ListItem>
                <ListItem>
                    <TextField label="Diplome" variant="outlined" fullWidth onChange={(e) => setDiplome(e.target.value)}
                        InputLabelProps={{ style: { color: '#000' } }}
                        inputProps={{ style: { backgroundColor: '#fff' } }}
                    />
                </ListItem>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                    <Button variant="contained" color="info" onClick={() => handleSubmitEntraineur()} sx={{ py: 2, width: 100 }}>
                        Valider
                    </Button>
                    <Button variant="contained" color="info" onClick={handleCloseAddEntraineur} sx={{ py: 2, width: 100 }}>
                        Fermer
                    </Button>
                </Box>
            </List>
        </Collapse>
    );
}

export default function EntraineurCard({ dispatch }) {
    const [openAddEntraineur, setOpenAddEntraineur] = useState(false);

    const entraineur = useSelector((state) => state.entraineurs.entraineursData);

    useEffect(() => { dispatch(getEntraineurs()) }, [dispatch]);

    const handleClickOpenAddEntraineur = () => { setOpenAddEntraineur(!openAddEntraineur) }
    const handleCloseAddEntraineur = () => { setOpenAddEntraineur(false) };

    return (
        <Card sx={{ my: 5 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant='h5' align='center' gutterBottom>
                    Entraineurs
                </Typography>
                <TableContainer component={Paper} sx={{ bgcolor: '#fff' }}>
                    <Table aria-label="simple table" height={"medium"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Prénom</TableCell>
                                <TableCell>Nom</TableCell>
                                <TableCell>Diplome</TableCell>
                                <TableCell>Editer</TableCell>
                                <TableCell>Supprimer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entraineur.map((value, index) => (<EntraineurRow key={index} value={value} dispatch={dispatch} />))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Button sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }} variant="contained" size="medium" fullWidth
                    onClick={() => handleClickOpenAddEntraineur()}
                >
                    Ajouter
                </Button>
            </CardActions>
            <AddEntraineurCollapse openAddEntraineur={openAddEntraineur} onClose={() => handleCloseAddEntraineur()} dispatch={dispatch} />
        </Card>
    );
}