import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

import { addTarifs, getTarifs, deleteTarifs, editTarifs } from '../../../store/actions/TarifsActions';

function TarifsRow(props) {

    const { value, dispatch } = props;
    const [openDelete, setOpenDelete] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [updateActivite, setUpdateActivite] = useState("")
    const [updatePrix, setUpdatePrix] = useState("")

    const handleOpenDelete = () => {
        setOpenDelete(!openDelete);
        setOpenUpdate(false);
    }

    const handleOpenUpdate = () => {
        setOpenDelete(false);
        setOpenUpdate(!openUpdate);
    }

    const handleClickDeleteTarif = async (e) => { await dispatch(deleteTarifs(value._id)); }

    const handleClickUpdateTarif = async (e) => {
        const data = {
            _id: value._id,
            activite: updateActivite ? updateActivite : value.activite,
            prix: updatePrix ? updatePrix : value.prix
        }
        await dispatch(editTarifs(data));
        setOpenUpdate(false);
    }

    useEffect(() => { dispatch(getTarifs()) }, [openDelete, updateActivite, updatePrix, openUpdate, dispatch]);

    return (
        <React.Fragment>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{value.activite}</TableCell>
                <TableCell>{value.prix},00€</TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenUpdate()}>
                        <CreateIcon sx={{ color: '#000' }} />
                    </Button>
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenDelete()}>
                        <DeleteIcon sx={{ color: '#ff0000' }} />
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>

                    {/* Delete */}
                    <Collapse in={openDelete} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                                align='center'
                                sx={{ my: 3, color: '#000' }}
                            >
                                Voulez vous vraiment supprimer cette activité: <br />
                                {value.activite}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => handleClickDeleteTarif()}
                                    sx={{ py: 2, width: 150 }}
                                >
                                    Oui
                                </Button>
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => setOpenDelete(false)}
                                    sx={{ py: 2, width: 150 }}
                                >
                                    Non
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>

                    {/* Edit */}
                    <Collapse in={openUpdate} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, maxWidth: 400, mx: 'auto' }}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                                align='center'
                                sx={{ my: 3, color: '#000' }}
                            >
                                Mise a jour de l'activité: <br />
                                {value.activite}
                            </Typography>
                            <TextField
                                label="Activité"
                                variant="outlined"
                                fullWidth
                                defaultValue={value.activite}
                                onChange={(e) => setUpdateActivite(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }}
                                sx={{ mb: 5 }}
                            />
                            <TextField
                                label="Prix"
                                variant="outlined"
                                type="number"
                                fullWidth
                                defaultValue={value.prix}
                                onChange={(e) => setUpdatePrix(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }}
                                sx={{ mb: 5 }}
                            />
                            <Box
                                sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 5 }}
                            >
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => handleClickUpdateTarif()}
                                    sx={{ py: 2, width: 150 }}
                                >
                                    Valider
                                </Button>
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => setOpenUpdate(false)}
                                    sx={{ py: 2, width: 150 }}
                                >
                                    Fermer
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function AddDialog(props) {
    const { onClose, openAdd, dispatch } = props;
    const [activite, setActivite] = useState('');
    const [prix, setPrix] = useState('');

    const handleCloseAdd = () => { onClose(); };

    const handleSubmitTarif = async (e) => {
        if (activite.length > 0) {
            await dispatch(addTarifs({ activite, prix }));
            setActivite('');
            setPrix('');
            onClose();
        }
    }

    useEffect(() => { dispatch(getTarifs()) }, [activite, dispatch]);

    return (
        <Dialog
            onClose={handleCloseAdd}
            open={openAdd}
            PaperProps={{ style: { backgroundColor: '#fff', } }}
        >
            <DialogTitle sx={{ color: '#000', textDecoration: 'none' }}>Ajouter une activité</DialogTitle>
            <List sx={{ pt: 0 }}>
                <ListItem>
                    <TextField
                        label="Activité"
                        variant="outlined"
                        onChange={(e) => setActivite(e.target.value)}
                        InputLabelProps={{ style: { color: '#000' } }}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label="Prix"
                        type="number"
                        variant="outlined"
                        onChange={(e) => setPrix(e.target.value)}
                        InputLabelProps={{ style: { color: '#000' } }}
                    />
                </ListItem>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => handleSubmitTarif()}
                        sx={{ py: 2, width: 100 }}
                    >
                        Valider
                    </Button>
                    <Button
                        variant="contained"
                        color="info"
                        onClick={handleCloseAdd}
                        sx={{ py: 2, width: 100 }}
                    >
                        Fermer
                    </Button>
                </Box>
            </List>
        </Dialog>
    );
}

export default function Tarifs({ tarif, dispatch }) {

    const [openAdd, setOpenAdd] = useState(false);
    const handleClickOpenAdd = () => { setOpenAdd(true); }
    const handleCloseAdd = () => { setOpenAdd(false); }

    return (
        <Card sx={{
            minWidth: 325,
            maxWidth: { xs: 350, md: 600 },
            width: { xs: '90%', xl: '48%' },
            my: 5, mx: { xs: 'auto', xl: 5 }
        }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant='h5' align='center' gutterBottom>
                    Tarifs
                </Typography>

                <TableContainer component={Paper} sx={{ bgcolor: '#fff' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Activités</TableCell>
                                <TableCell>Prix</TableCell>
                                <TableCell>Editer</TableCell>
                                <TableCell>Supprimer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tarif.map((value, index) => (
                                <TarifsRow key={index} value={value} dispatch={dispatch} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </CardContent>
            <CardActions>
                <Button
                    sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }}
                    variant="contained"
                    size="medium"
                    fullWidth
                    onClick={() => handleClickOpenAdd()}
                >
                    Ajouter
                </Button>
            </CardActions>
            <AddDialog openAdd={openAdd} onClose={() => handleCloseAdd()} dispatch={dispatch} />
        </Card>
    );
}
