/*
 * Import Actions { ... }
 * ********************** */
import * as Actions from "../actions/ActionsTypes";

/*
 * Selector
 * ******** */
const initialState = {
  entraineursData: [],
};

/*
 * Reducers
 * ******** */
export function EntraineursReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ENTRAINEURS_DATA:
      return {
        ...state, entraineursData: action.payload
      };
    case Actions.ADD_ENTRAINEURS_DATA:
      return {
        ...state, entraineursData: action.payload
      };
    case Actions.DELETE_ENTRAINEURS_DATA:
      return {
        ...state, entraineursData: action.payload, 
      };
      case Actions.EDIT_ENTRAINEURS_DATA:
        return {
          ...state, entraineursData: action.payload, 
        };
    default:
      return state;
  }
}

/*
 * Getters
 * ******* */
