import React from 'react';
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material';

import AdminLayout from '../layouts/AdminLayout';
import EntraineurCard from '../components/admin/adminClub/EntraineurCard';
import JugesCard from '../components/admin/adminClub/JugeCard';
import AdminTeamCard from '../components/admin/adminClub/AdminTeamCard';
import SalleCard from '../components/admin/adminClub/SalleCard';
import ChampionCard from '../components/admin/adminClub/ChampionsCard';
import HistoireClubCard from '../components/admin/adminClub/HistoireClubCard';
import AuthAdmin from '../components/AuthAdmin';

import { useDispatch } from "react-redux";

const AdminClub = () => {
    const dispatch = useDispatch();

    return (
        <AdminLayout>
            <Typography variant="h2" color="initial" align='center' sx={{ fontSize: { xs: "2em", md: "6em" } }} >
                Gestion du club
            </Typography>
            <Box sx={{ width: "80%", m: 'auto' }}>
                <HistoireClubCard dispatch={dispatch} />
                <SalleCard dispatch={dispatch} />
                <AdminTeamCard dispatch={dispatch} />
                <EntraineurCard dispatch={dispatch} />
                <JugesCard dispatch={dispatch} />
                <ChampionCard dispatch={dispatch} />
            </Box>
        </AdminLayout>
    );
};

export default AuthAdmin(AdminClub);