/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";
import jwt_decode from 'jwt-decode'

/*
 * Import types { ... }
 * ******************** */
import { LOGIN } from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Login
export const login = (data) => {
    return (dispatch) => {
        return api
            .post("/login/", data)
            .then((res) => {
                if (res.data.success === 'success') {
                    if (res.data.token) localStorage["user_token"] = res.data.token;
                    res.data.token = jwt_decode(res.data.token)
                    res.data.authenticate = true
                    dispatch({ type: LOGIN, payload: res.data });
                } else {
                    res.data.authenticate = false
                    dispatch({ type: LOGIN, payload: res.data });
                }
            })
            .catch((err) => console.log(err));
    };
};