import React, { useEffect, useState } from 'react';
import { ListItem, ListItemIcon, ListItemText, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from '@mui/material/GlobalStyles';
import { themeMain } from "../config/theme";
import { style } from '../config/globalStyle'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RowingIcon from '@mui/icons-material/Rowing';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupsIcon from '@mui/icons-material/Groups';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Fond from '../assets/fond-accueil.jpeg';
import FondMobile from '../assets/fond-mobile.jpeg';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Image from "../assets/VSFLogo.png";
import CardMedia from "@mui/material/CardMedia";
import HomeIcon from '@mui/icons-material/Home';

import { useNavigate } from 'react-router-dom';

const Main = styled('main')(
    ({ theme }) => ({
        flexGrow: 1,
        backgroundImage: `url(${Fond})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        backgroundAttachment: 'fixed',
    }),
);

const MainMobile = styled('main')(
    ({ theme }) => ({
        flexGrow: 1,
        backgroundImage: `url(${FondMobile})`,
        backgroundSize: '100%',
        minHeight: '100vh',
        backgroundAttachment: 'fixed',
        backgroundColor: 'black'
    }),
);


export default function MainLayout({ children }) {
    const navigate = useNavigate();
    const [openDrawerMobile, setOpenDrawerMobile] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const handleDrawerMobileOpen = () => { setOpenDrawerMobile(!openDrawerMobile); };
    const handleDrawerMobileClose = () => { setOpenDrawerMobile(false); };
    const MenuList = [
        { titre: 'Le club', icon: <GroupsIcon />, link: '/club' },
        { titre: 'Inscription', icon: <AssignmentIndIcon />, link: '/inscription' },
        { titre: 'Nos activités', icon: <RowingIcon />, link: '/activites' },
        { titre: 'Boutique', icon: <LocalGroceryStoreIcon />, link: '/store' },
        { titre: 'Espace membre', icon: <AccountCircleIcon />, link: '/espace-membre' },
    ]
    const MenuListResponsive = [
        { titre: 'Accueil', icon: <HomeIcon />, link: '/' },
        { titre: 'Le club', icon: <GroupsIcon />, link: '/club' },
        { titre: 'Inscription', icon: <AssignmentIndIcon />, link: '/inscription' },
        { titre: 'Nos activités', icon: <RowingIcon />, link: '/activites' },
        { titre: 'Boutique', icon: <LocalGroceryStoreIcon />, link: '/store' },
        { titre: 'Espace membre', icon: <AccountCircleIcon />, link: '/espace-membre' },
    ]

    const onScroll = (e) => { setScrollTop(e.target.documentElement.scrollTop) }
    useEffect(() => { window.addEventListener('scroll', onScroll) }, []);
    useEffect(() => { if (scrollTop > 50) { setIsActive(true) } else { setIsActive(false) } }, [scrollTop])
    let classNameMenu = "menu";
    if (isActive) { classNameMenu = 'menu affix' } else if (!isActive) { classNameMenu = 'menu' }

    return (
        <ThemeProvider theme={themeMain}>
            <CssBaseline />
            <GlobalStyles styles={{ ...style }} />
            <Box sx={{ display: 'flex' }}>
                <AppBar className={classNameMenu} component="nav"
                    sx={{
                        bgcolor: { xs: 'rgba(45, 53, 60, 1)', md: 'rgba(45, 53, 60, 0)' },
                        boxShadow: 'none', paddingY: { xs: '5px', md: '20px' },
                    }}
                >
                    <Toolbar sx={{ justifyContent: 'space-between' }} >
                        <IconButton color="inherit" aria-label="open drawer" edge="start"
                            onClick={handleDrawerMobileOpen}
                            sx={{ mr: 2, display: { md: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Button onClick={() => navigate({ pathname: '/' })} variant="h6"
                            sx={{ display: { xs: 'none', md: 'block' } }}
                        >
                            <CardMedia component="img" image={Image} alt="Logo-VSF"
                                sx={{ width: 100, height: 100, position: 'absolute', top: '-25px' }} />
                        </Button>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            {MenuList.map((item, index) => (
                                <Button key={index} sx={{ color: '#fff', fontSize: '1.13rem' }} onClick={() => navigate({ pathname: `${item.link}` })}>
                                    {item.titre}
                                </Button>
                            ))}
                        </Box>
                    </Toolbar>
                </AppBar>
                {/* Drawer mobile */}
                <SwipeableDrawer
                    anchor='bottom'
                    open={openDrawerMobile}
                    onClose={() => handleDrawerMobileClose()}
                    onOpen={() => handleDrawerMobileOpen()}
                    PaperProps={{ style: { backgroundColor: '#fff' } }}
                >
                    <Box>
                        <List>
                            {MenuListResponsive.map((text, index) => (
                                <ListItem button key={index} onClick={() => navigate({ pathname: `${text.link}` })}>
                                    <ListItemIcon>
                                        {text.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={text.titre} primaryTypographyProps={{ style: { color: '#000' } }} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </SwipeableDrawer>
            </Box>
            <Main sx={{ display: { xs: 'none', sm: 'block' } }}>
                {children}
            </Main>

            <MainMobile sx={{ display: { xs: 'block', sm: 'none' } }}>
                {children}
            </MainMobile>

        </ThemeProvider >
    );
};