import React, { useState, useEffect } from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import MainLayout from '../layouts/MainLayout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Image from "../assets/VSFLogo.png";
import Footer from '../components/Footer';

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { login } from '../store/actions/AuthActions';

const EspaceMembre = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const authenticate = useSelector(state => state.auth.authenticate);
    const handleClickShowPassword = () => { setShowPassword(!showPassword) };
    const handleMouseDownPassword = (event) => { event.preventDefault(); };
    const SubmitFormId = () => {
        if (mail && password) {
            dispatch(login({ mail, password }));
            setPassword('');
            setError('');
        } else {
            setError('Tous les champs doivent être remplis!');
        }
    };
    useEffect(() => { if (authenticate) { navigate("/admin") } }, [authenticate, navigate])

    return (
        <MainLayout>
            <React.Fragment>
                <CssBaseline />

                {/* Titre */}
                <Box component="div" sx={{ pt: 5, padding: '85px 16px 24px' }}>
                    <Typography variant='h2' align='center' sx={{ fontSize: { xs: "2.5em", md: "5em" }, my: 2 }} >
                        Espace membre
                    </ Typography>
                </Box>

                {/* Fromulaire */}
                <Box
                    sx={{
                        width: { xs: 380, md: 500 },
                        height: 610,
                        mx: 'auto',
                        my: 5,
                        bgcolor: 'white',
                        p: '50px 50px',
                        borderRadius: 1
                    }}
                >
                    <CardMedia
                        component="img"
                        image={Image}
                        alt="Logo-VSF"
                        sx={{ width: 150, height: 150, m: 'auto' }}
                    />
                    <Typography variant='h2' align='center'
                        sx={{ fontSize: { xs: "2.5em", md: "2.5em" }, my: 2, color: 'black' }} >
                        Connexion
                    </ Typography>

                    <Stack component="form">
                        <TextField label='identifiant' name='mail' variant="outlined" fullWidth
                            onChange={(e) => setMail(e.target.value)}
                            sx={{ my: 1 }}
                            inputProps={{ style: { color: 'black' } }}
                        />
                        <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                            <OutlinedInput
                                name='password' type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                sx={{ bgcolor: '#fff', color: '#000' }}
                                label="Password"
                                autoComplete="off"
                            />
                        </FormControl>
                    </Stack>
                    <Button variant="contained" fullWidth onClick={() => SubmitFormId()}
                        sx={{ bgcolor: 'info', fontWeight: 'bold', my: 1, py: 1 }}>
                        Envoyer
                    </Button>
                    {error.length > 0 &&
                        <Box sx={{ my: 3, color: '#ff0000' }} >
                            <Typography variant='body1' align='center' >{error}</Typography>
                        </Box>
                    }
                </Box>
            </React.Fragment>

            {/* Footer */}
            <Footer />

        </MainLayout>
    );
};

export default EspaceMembre;