/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
    GET_PROCEDURES_DATA,
    ADD_PROCEDURES_DATA,
    DELETE_PROCEDURES_DATA,
    EDIT_PROCEDURES_DATA,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get procedures
export const getProcedures = () => {
    return (dispatch) => {
        return api
            .get("/procedures/")
            .then((res) => {
                dispatch({
                    type: GET_PROCEDURES_DATA,
                    payload: res.data.dbProcedures
                });
            })
            .catch((err) => console.log(err));
    };
};

// Add procedures
export const addProcedures = (data) => {
    return (dispatch) => {
        return api
            .post("/procedures/", data)
            .then((res) => {
                dispatch({
                    type: ADD_PROCEDURES_DATA,
                    payload: res.data.dbProcedures
                });
            })
            .catch((err) => console.log(err));
    };
};

// DeleteOne Procedures
export const deleteProcedures = (id) => {
    return (dispatch) => {
        return api
            .delete(`/procedures/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_PROCEDURES_DATA,
                    payload: res.data.dbProcedures
                });
            })
            .catch((err) => console.log(err));
    };
};

// Edit Procedures
export const editProcedures = (data) => {
    return (dispatch) => {
        return api
            .put(`/procedures/${data._id}`, data)
            .then((res) => {
                dispatch({
                    type: EDIT_PROCEDURES_DATA,
                    payload: res.data.dbProcedures
                });
            })
            .catch((err) => console.log(err));
    };
}