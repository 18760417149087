/*
 * Import Actions { ... }
 * ********************** */
import * as Actions from "../actions/ActionsTypes";

/*
 * Selector
 * ******** */
const initialState = {
  activitesData: [],
  imagesData: [],
};

/*
 * Reducers
 * ******** */
export function ActivitesReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ACTIVITES_DATA:
      return {
        ...state, activitesData: action.payload
      };
    case Actions.ADD_ACTIVITES_DATA:
      return {
        ...state, activitesData: action.payload
      };
    case Actions.DELETE_ACTIVITES_DATA:
      return {
        ...state, activitesData: action.payload,
      };
    case Actions.EDIT_ACTIVITES_DATA:
      return {
        ...state, activitesData: action.payload,
      };
    case Actions.ADD_ACTIVITES_IMAGES_DATA:
      return {
        ...state, imagesData: action.payload,
      };
    case Actions.DELETE_ACTIVITES_IMAGES_DATA:
      return {
        ...state, imagesData: action.payload,
      };
    default:
      return state;
  }
}

/*
 * Getters
 * ******* */
