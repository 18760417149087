/*
 * Action types { ... }
 * ******************** */

// User
export const GET_USER_DATA = "GET_USER_DATA";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const DELETE_USER_DATA = "DELETE_USER_DATA";
export const EDIT_USER_DATA = "EDIT_USER_DATA";

// Activites
export const GET_ACTIVITES_DATA = "GET_ACTIVITES_DATA";
export const ADD_ACTIVITES_DATA = "ADD_ACTIVITES_DATA";
export const DELETE_ACTIVITES_DATA = "DELETE_ACTIVITES_DATA";
export const EDIT_ACTIVITES_DATA = "EDIT_ACTIVITES_DATA";
export const ADD_ACTIVITES_IMAGES_DATA = "ADD_ACTIVITES_IMAGES_DATA";
export const DELETE_ACTIVITES_IMAGES_DATA = "DELETE_ACTIVITES_IMAGES_DATA";

// Calendrier
export const GET_CALENDRIER_DATA = "GET_CALENDRIER_DATA";
export const ADD_CALENDRIER_DATA = "ADD_CALENDRIER_DATA";
export const DELETE_CALENDRIER_DATA = "DELETE_CALENDRIER_DATA";
export const EDIT_CALENDRIER_DATA = "EDIT_CALENDRIER_DATA";

// Champion
export const GET_CHAMPIONS_DATA = "GET_CHAMPIONS_DATA";
export const ADD_CHAMPIONS_DATA = "ADD_CHAMPIONS_DATA";
export const DELETE_CHAMPIONS_DATA = "DELETE_CHAMPIONS_DATA";
export const EDIT_CHAMPIONS_DATA = "EDIT_CHAMPIONS_DATA";

// Entraineurs
export const GET_ENTRAINEURS_DATA = "GET_ENTRAINEURS_DATA";
export const ADD_ENTRAINEURS_DATA = "ADD_ENTRAINEURS_DATA";
export const DELETE_ENTRAINEURS_DATA = "DELETE_ENTRAINEURS_DATA";
export const EDIT_ENTRAINEURS_DATA = "EDIT_ENTRAINEURS_DATA";

// Equipe Administrative
export const GET_EQUIPEADMINISTRATIVE_DATA = "GET_EQUIPEADMINISTRATIVE_DATA";
export const ADD_EQUIPEADMINISTRATIVE_DATA = "ADD_EQUIPEADMINISTRATIVE_DATA";
export const DELETE_EQUIPEADMINISTRATIVE_DATA = "DELETE_EQUIPEADMINISTRATIVE_DATA";
export const EDIT_EQUIPEADMINISTRATIVE_DATA = "EDIT_EQUIPEADMINISTRATIVE_DATA";

// Juges
export const GET_JUGES_DATA = "GET_JUGES_DATA";
export const ADD_JUGES_DATA = "ADD_JUGES_DATA";
export const DELETE_JUGES_DATA = "DELETE_JUGES_DATA";
export const EDIT_JUGES_DATA = "EDIT_JUGES_DATA";

// Procédures
export const GET_PROCEDURES_DATA = "GET_PROCEDURES_DATA";
export const ADD_PROCEDURES_DATA = "ADD_PROCEDURES_DATA";
export const DELETE_PROCEDURES_DATA = "DELETE_PROCEDURES_DATA";
export const EDIT_PROCEDURES_DATA = "EDIT_PROCEDURES_DATA";

// Tarifs
export const GET_LINK_DATA = "GET_LINK_DATA";
export const ADD_LINK_DATA = "ADD_LINK_DATA";
export const DELETE_LINK_DATA = "DELETE_LINK_DATA";
export const EDIT_LINK_DATA = "EDIT_LINK_DATA";

// Salle
export const GET_SALLE_DATA = "GET_SALLE_DATA";
export const ADD_SALLE_DATA = "ADD_SALLE_DATA";
export const DELETE_SALLE_DATA = "DELETE_SALLE_DATA";

// Tarifs
export const GET_TARIFS_DATA = "GET_TARIFS_DATA";
export const ADD_TARIFS_DATA = "ADD_TARIFS_DATA";
export const DELETE_TARIFS_DATA = "DELETE_TARIFS_DATA";
export const EDIT_TARIFS_DATA = "EDIT_TARIFS_DATA";

// YearsInscription
export const GET_YEARSINSCRIPTION_DATA = "GET_YEARSINSCRIPTION_DATA";
export const ADD_YEARSINSCRIPTION_DATA = "ADD_YEARSINSCRIPTION_DATA";
export const EDIT_YEARSINSCRIPTION_DATA = "EDIT_YEARSINSCRIPTION_DATA";

// HistoireClub
export const GET_HISTOIRECLUB_DATA = 'GET_HISTOIRECLUB_DATA';
export const ADD_HISTOIRECLUB_DATA = 'ADD_HISTOIRECLUB_DATA';
export const EDIT_HISTOIRECLUB_DATA = 'EDIT_HISTOIRECLUB_DATA';
export const DELETE_HISTOIRECLUB_DATA = 'DELETE_HISTOIRECLUB_DATA';

// Login
export const LOGIN = 'LOGIN';

// Image inscription
export const GET_IMAGEINSCRIPTION_DATA = 'GET_IMAGEINSCRIPTION_DATA';
export const ADD_IMAGEINSCRIPTION_DATA = 'ADD_IMAGEINSCRIPTION_DATA';
export const DELETE_IMAGEINSCRIPTION_DATA = 'DELETE_IMAGEINSCRIPTION_DATA';

// Boutique
export const GET_STORE_DATA = 'GET_STORE_DATA';
export const ADD_STORE_DATA = 'ADD_STORE_DATA';
export const EDIT_STORE_DATA = 'EDIT_STORE_DATA';
export const DELETE_STORE_DATA = 'DELETE_STORE_DATA';