/*
 * Import Actions { ... }
 * ********************** */
import * as Actions from "../actions/ActionsTypes";

/*
 * Selector
 * ******** */
const initialState = {
  linkData: [],
};

/*
 * Reducers
 * ******** */
export function LinkReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_LINK_DATA:
      return {
        ...state, linkData: action.payload
      };
    case Actions.ADD_LINK_DATA:
      return {
        ...state, linkData: action.payload
      };
    case Actions.DELETE_LINK_DATA:
      return {
        ...state, linkData: action.payload, 
      };
      case Actions.EDIT_LINK_DATA:
        return {
          ...state, linkData: action.payload, 
        };
    default:
      return state;
  }
}

/*
 * Getters
 * ******* */
