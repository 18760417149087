import React, { useEffect, useState } from 'react';
import AdminLayout from '../layouts/AdminLayout';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Collapse from '@mui/material/Collapse';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import CardActions from '@mui/material/CardActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { urlImg } from '../utils/url';
import ImageIcon from '@mui/icons-material/Image';
import InputLabel from '@mui/material/InputLabel';

import AuthAdmin from '../components/AuthAdmin';

import { useSelector, useDispatch } from "react-redux";
import {
    getActivites,
    addActivites,
    deleteActivites,
    editActivites,
    addActivitesImages,
    deleteActivitesImages
} from '../store/actions/ActivitesActions';

function ActivitesDialog(props) {
    const { handleCloseActivites, openActivites, dispatch } = props;
    const [titre, setTitre] = useState('');
    const [description, setDescription] = useState('');
    const handleSubmitActivite = async (e) => {
        if (titre.length > 0 && description.length > 0) {
            await dispatch(addActivites({ titre, description }));
            setTitre('');
            setDescription('');
        }
    }

    return (
        <Dialog onClose={handleCloseActivites} open={openActivites} PaperProps={{ style: { backgroundColor: '#fff', maxWidth: '800px', width: '90%' } }}>
            <DialogTitle sx={{ color: '#000', textDecoration: 'none' }}>
                Ajouter une activité
            </DialogTitle>
            <TextField label="titre" variant="outlined" fullWidth multiline
                onChange={(e) => setTitre(e.target.value)}
                InputLabelProps={{ style: { color: '#000' } }}
                sx={{ mb: 5, mx: 'auto', width: '80%' }}
            />
            <TextField label="description" variant="outlined" fullWidth multiline
                onChange={(e) => setDescription(e.target.value)}
                InputLabelProps={{ style: { color: '#000' } }}
                sx={{ mb: 5, mx: 'auto', width: '80%' }}
            />
            <Box
                sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                <Button variant="contained" color="info" onClick={() => handleSubmitActivite()} sx={{ py: 2, width: 100 }} >
                    Valider
                </Button>
                <Button variant="contained" color="info" onClick={handleCloseActivites} sx={{ py: 2, width: 100 }} >
                    Fermer
                </Button>
            </Box>
        </Dialog>
    )
}

function AddImageCollapse(props) {
    const { value, setOpenAdd, openAdd, dispatch } = props;
    const [image, setImage] = useState({});
    const [imagePreview, setImagePreview] = useState();
    const [preview, setPreview] = useState();
    const handleCloseAdd = () => { setOpenAdd(false); };

    const handleSubmit = (e) => {
        if (image.name) {
            const formData = new FormData();
            formData.append('image', image);
            dispatch(addActivitesImages(value._id, formData));
            setImage('');
            setImagePreview('');
            setOpenAdd(false);
        } else {
            handleCloseAdd()
        }
    }
    const handleDeleteImage = () => { setImagePreview('') };
    useEffect(() => {
        if (!imagePreview) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(imagePreview)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [imagePreview])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setImagePreview(undefined)
            return
        }
        setImagePreview(e.target.files[0])
        setImage(e.target.files[0])
    }
    useEffect(() => { setTimeout(() => { dispatch(getActivites()) }, 2000) }, [dispatch, image]);

    return (
        <Collapse in={openAdd} timeout="auto" unmountOnExit>
            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ mb: 3, color: '#fff' }}>
                Ajouter une image:
            </Typography>
            <List sx={{ pt: 0 }}>
                <ListItem>
                    <TextField id="imageInput" sx={{ display: 'none' }} size="small" type="file" inputProps={{ accept: "image/*" }} onChange={onSelectFile} />
                    <InputLabel
                        sx={{
                            display: 'block',
                            color: '#FFF',
                            background: '#0288d1',
                            p: '20px',
                            fontSize: '1rem',
                            cursor: 'pointer',
                            width: '250px',
                            m: 'auto',
                            borderRadius: 2
                        }}
                        htmlFor="imageInput"
                        className="image-button"
                    >
                        <ImageIcon sx={{ fontSize: '125%', mr: '.3rem', verticalAlign: 'sub' }} />
                        Choisir une image
                    </InputLabel>
                </ListItem>
                {imagePreview &&
                    <ImageListItem sx={{ width: '300px', m: 'auto' }}>
                        <img src={preview} className="image-preview" alt='image_test' />
                        <ImageListItemBar
                            sx={{ background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)' }}
                            position="top"
                            actionIcon={
                                <IconButton aria-label={`star`} onClick={() => handleDeleteImage()}>
                                    <DeleteIcon sx={{ color: '#ff0000' }} />
                                </IconButton>
                            }
                            actionPosition="right"
                        />
                    </ImageListItem>
                }
                <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                    <Button variant="contained" color="info" onClick={() => handleSubmit()} sx={{ py: 2, width: 100 }}>
                        Valider
                    </Button>
                    <Button variant="contained" color="info" onClick={() => handleCloseAdd()} sx={{ py: 2, width: 100 }}>
                        Fermer
                    </Button>
                </Box>
            </List>
        </Collapse>
    );
}

function ImageListRow(props) {
    const { item, dispatch } = props
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const handleDeleteDialog = () => { setOpenDeleteDialog(!openDeleteDialog) };
    const handleClickDeleteActivitesImage = () => {
        dispatch(deleteActivitesImages(item._id));
        setOpenDeleteDialog(false);
    }

    useEffect(() => { dispatch(getActivites()) }, [dispatch, openDeleteDialog]);

    return (
        <ImageListItem>
            <img src={`${urlImg + item.image}`} alt={item.name} loading="lazy" />
            <ImageListItemBar
                sx={{ background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)' }}
                title={item.name}
                position="top"
                actionIcon={
                    <IconButton aria-label={`star ${item.name}`} onClick={() => handleDeleteDialog()}>
                        <DeleteIcon sx={{ color: '#ff0000' }} />
                    </IconButton>
                }
                actionPosition="right"
            />
            {/* Dialog Delete Image */}
            <Dialog onClose={() => handleDeleteDialog()} open={openDeleteDialog}>
                <DialogTitle sx={{ textDecoration: 'none' }}>
                    Voulez vous vraiment supprimer cette image?
                </DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                    <Button variant="contained" color="error" onClick={() => handleClickDeleteActivitesImage()} sx={{ py: 2, width: 150 }}>
                        Oui
                    </Button>
                    <Button variant="contained" color="info" onClick={() => handleDeleteDialog()} sx={{ py: 2, width: 150 }}>
                        Non
                    </Button>
                </Box>
            </Dialog>

        </ImageListItem>
    )
}

function ActivitesRow(props) {
    const { value, dispatch } = props;
    const [openDeleteActivites, setOpenDeleteActivites] = useState(false);
    const [openUpdateActivites, setOpenUpdateActivites] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [title, setTitre] = useState(value.titre);
    const [describe, setDescription] = useState(value.description);
    const imageActivites = value.activitesImagesId;

    const handleClickOpen = () => { setOpenAdd(!openAdd); };

    const handleOpenDeleteActivites = () => {
        setOpenDeleteActivites(!openDeleteActivites);
        setOpenUpdateActivites(false);
    }

    const handleOpenUpdateActivites = () => {
        setOpenDeleteActivites(false);
        setOpenUpdateActivites(!openUpdateActivites);
    }

    const handleClickDeleteActivites = (e) => {
        dispatch(deleteActivites(value._id));
        setOpenDeleteActivites(false);
    }

    const handleClickUpdateActivites = async () => {
        if (title || describe) {
            const data = {
                id: value._id,
                titre: title ? title : value.titre,
                description: describe ? describe : value.description
            }
            await dispatch(editActivites(value._id, data));
            setOpenUpdateActivites(false);
        }
    }

    return (
        <Card sx={{ m: 5 }}>
            <CardContent>
                <Box sx={{ position: 'absolute', float: 'right' }}>
                    <Button onClick={() => handleOpenUpdateActivites()}><CreateIcon sx={{ color: '#fff' }} /></Button>
                    <Button onClick={() => handleOpenDeleteActivites()}><DeleteIcon sx={{ color: '#ff0000' }} /></Button>
                </Box>
                <Typography sx={{ mb: 1.5 }} variant='h5' align='center' gutterBottom>{value.titre}</Typography>
                <Typography variant='body1' align='center' sx={{ color: '#fff' }}>{value.description}</Typography>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                    <CardActions>
                        <ImageList cols={3}>
                            {imageActivites.map((item, index) => (
                                <ImageListRow key={index} item={item} dispatch={dispatch} />
                            ))}
                        </ImageList>
                    </CardActions>
                </Box>
                {/* Mobile version */}
                <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>
                    <CardActions>
                        <ImageList cols={1}>
                            {imageActivites.map((item, index) => (
                                <ImageListRow key={index} item={item} dispatch={dispatch} />
                            ))}
                        </ImageList>
                    </CardActions>
                </Box>
                <Box>
                    <CardActions>
                        <Button
                            sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }}
                            variant="contained" size="medium" fullWidth
                            onClick={() => handleClickOpen()}
                        >
                            Ajouter
                        </Button>
                    </CardActions>

                    {/* Collapse ajout image */}
                    <AddImageCollapse value={value} openAdd={openAdd} setOpenAdd={setOpenAdd} dispatch={dispatch} />
                </Box>
            </CardContent>

            {/* Delete */}
            <Collapse in={openDeleteActivites} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                    <Typography variant="body1" gutterBottom component="div" align='center' sx={{ my: 3, color: '#fff' }}>
                        Voulez vous vraiment supprimer cette activité: <br />
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                        <Button variant="contained" color="error" sx={{ py: 2, width: 150 }} onClick={() => handleClickDeleteActivites()}>
                            Oui
                        </Button>
                        <Button variant="contained" color="info" sx={{ py: 2, width: 150 }} onClick={() => setOpenDeleteActivites(false)}>
                            Non
                        </Button>
                    </Box>
                </Box>
            </Collapse>

            {/* Edit */}
            <Collapse in={openUpdateActivites} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1, minWidth: 400, maxWidth: 600, mx: 'auto' }}>
                    <Typography variant="body1" gutterBottom component="div" align='center' sx={{ my: 3, color: '#fff' }}>
                        Mise a jour de l'activité'
                    </Typography>
                    <TextField label="Titre" variant="outlined" fullWidth defaultValue={title}
                        onChange={(e) => setTitre(e.target.value)} multiline
                        InputLabelProps={{ style: { color: '#fff' } }} sx={{ mb: 5 }}
                        inputProps={{ style: { color: '#fff' } }}
                    />
                    <TextField label="Description" variant="outlined" fullWidth defaultValue={describe}
                        onChange={(e) => setDescription(e.target.value)} multiline
                        InputLabelProps={{ style: { color: '#fff' } }} sx={{ mb: 5 }}
                        inputProps={{ style: { color: '#fff' } }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 5 }}>
                        <Button variant="contained" color="info" sx={{ py: 2, width: 150 }} onClick={() => handleClickUpdateActivites()}>
                            Valider
                        </Button>
                        <Button variant="contained" color="info" sx={{ py: 2, width: 150 }} onClick={() => setOpenUpdateActivites(false)}>
                            Fermer
                        </Button>
                    </Box>
                </Box>
            </Collapse>
        </Card >
    )
}

const AdminActivites = () => {

    const dispatch = useDispatch();
    const activities = useSelector((state) => state.activites.activitesData)
    const [openActivites, setOpenActivites] = useState(false);

    const handleClickOpenActivites = () => { setOpenActivites(true) }
    const handleCloseActivites = () => { setOpenActivites(false) }

    useEffect(() => { dispatch(getActivites()) }, [dispatch]);

    return (
        <AdminLayout>
            <Typography variant="h2" color="initial" align='center' sx={{ fontSize: { xs: "2em", md: "6em" } }} >
                Gestion des activités
            </Typography>
            <Box sx={{ display: 'block', flexWrap: 'wrap', alignItems: 'baseline', justifyContent: 'space-around' }}>
                {activities.map((value, index) => (
                    <ActivitesRow key={index} value={value} dispatch={dispatch} />
                ))}
                <Box sx={{ m: 5 }}>
                    <Button
                        sx={{ color: '#fff', backgroundColor: '#0063cc', py: 2 }}
                        variant="contained" size="small" fullWidth
                        onClick={() => handleClickOpenActivites()}
                    >
                        Ajouter une activité
                    </Button>
                </Box>
            </Box>
            <ActivitesDialog openActivites={openActivites} handleCloseActivites={handleCloseActivites} dispatch={dispatch} />
        </AdminLayout>
    );
};

export default AuthAdmin(AdminActivites);