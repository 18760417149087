import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { useSelector } from "react-redux";
import { addJuges, getJuges, deleteJuges, editJuges } from '../../../store/actions/JugesActions';

function JugeRow(props) {

    const { value, dispatch } = props;
    const [openDeleteJuge, setOpenDeleteJuge] = useState(false);
    const [openUpdateJuge, setOpenUpdateJuge] = useState(false);
    const [prenom, setPrenom] = useState(value.prenom);
    const [nom, setNom] = useState(value.nom);

    const handleOpenDeleteJuge = () => {
        setOpenDeleteJuge(!openDeleteJuge);
        setOpenUpdateJuge(false);
    }

    const handleOpenUpdateJuge = () => {
        setOpenDeleteJuge(false);
        setOpenUpdateJuge(!openUpdateJuge);
    }

    const handleClickDeleteJuge = (e) => {
        dispatch(deleteJuges(value._id));
        setOpenDeleteJuge(false)
    }

    const handleClickUpdateJuge = async () => {
        const data = { prenom, nom }
        await dispatch(editJuges(value._id, data));
        setOpenUpdateJuge(false);
    }

    useEffect(() => { dispatch(getJuges()) }, [openDeleteJuge, prenom, nom, openUpdateJuge, dispatch]);

    return (
        <React.Fragment>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{value.prenom}</TableCell>
                <TableCell>{value.nom}</TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenUpdateJuge()}><CreateIcon sx={{ color: '#000' }} /></Button>
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleOpenDeleteJuge()}><DeleteIcon sx={{ color: '#ff0000' }} /></Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>

                    {/* Delete */}
                    <Collapse in={openDeleteJuge} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ my: 3, color: '#000' }} >
                                Voulez vous vraiment supprimer ce juge:
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                                <Button variant="contained" color="error" onClick={() => handleClickDeleteJuge()} sx={{ py: 2, width: 150 }} >
                                    Oui
                                </Button>
                                <Button
                                    variant="contained" color="info" onClick={() => setOpenDeleteJuge(false)} sx={{ py: 2, width: 150 }} >
                                    Non
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>

                    {/* Edit */}
                    <Collapse in={openUpdateJuge} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, minWidth: 400, maxWidth: 600, mx: 'auto' }}>
                            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ my: 3, color: '#000' }} >
                                Mise a jour du juge:
                            </Typography>
                            <TextField label="Prénom" variant="outlined" fullWidth defaultValue={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }} sx={{ mb: 5 }}
                            />
                            <TextField label="Nom" variant="outlined" fullWidth defaultValue={nom}
                                onChange={(e) => setNom(e.target.value)}
                                InputLabelProps={{ style: { color: '#000' } }} sx={{ mb: 5 }}
                            />
                            <Box
                                sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 5 }}
                            >
                                <Button variant="contained" color="info" onClick={() => handleClickUpdateJuge()} sx={{ py: 2, width: 150 }} >
                                    Valider
                                </Button>
                                <Button variant="contained" color="info" onClick={() => setOpenUpdateJuge(false)} sx={{ py: 2, width: 150 }} >
                                    Fermer
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

function AddJugeCollapse(props) {
    const { onClose, openAddJuge, dispatch } = props;
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');

    const handleCloseAddJuge = () => { onClose(); };

    const handleSubmitJuge = async (e) => {
        const dataFormJuge = { nom, prenom };
        if (nom.length > 0 && prenom.length > 0) {
            await dispatch(addJuges(dataFormJuge));
            setNom('');
            setPrenom('');
            onClose();
        }
    }

    useEffect(() => { dispatch(getJuges()) }, [dispatch, nom, prenom]);

    return (
        <Collapse in={openAddJuge} timeout="auto" unmountOnExit >
            <Typography variant="body1" gutterBottom component="div" align='center' sx={{ mb: 3, color: '#fff' }} >
                Ajouter un juge:
            </Typography>
            <List sx={{ pt: 0, minWidth: 400, maxWidth: 600, mx: 'auto' }}>
                <ListItem>
                    <TextField label="Prénom" variant="outlined" fullWidth onChange={(e) => setPrenom(e.target.value)}
                        InputLabelProps={{ style: { color: '#000' } }} inputProps={{ style: { backgroundColor: '#fff' } }}
                    />
                </ListItem>
                <ListItem>
                    <TextField label="Nom" variant="outlined" fullWidth onChange={(e) => setNom(e.target.value)}
                        InputLabelProps={{ style: { color: '#000' } }} inputProps={{ style: { backgroundColor: '#fff' } }}
                    />
                </ListItem>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                    <Button variant="contained" color="info" onClick={() => handleSubmitJuge()} sx={{ py: 2, width: 100 }} >
                        Valider
                    </Button>
                    <Button variant="contained" color="info" onClick={handleCloseAddJuge} sx={{ py: 2, width: 100 }} >
                        Fermer
                    </Button>
                </Box>
            </List>
        </Collapse>
    );
}

export default function JugesCard({ dispatch }) {
    const [openAddJuge, setOpenAddJuge] = useState(false);

    const juge = useSelector((state) => state.juges.jugesData);

    useEffect(() => { dispatch(getJuges()) }, [dispatch]);

    const handleClickOpenAddJuge = () => { setOpenAddJuge(!openAddJuge) };
    const handleCloseAddJuge = () => { setOpenAddJuge(false); };

    return (
        <Card sx={{ my: 5 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant='h5' align='center' gutterBottom>
                    Juges
                </Typography>
                <TableContainer component={Paper} sx={{ bgcolor: '#fff' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Prénom</TableCell>
                                <TableCell>Nom</TableCell>
                                <TableCell>Editer</TableCell>
                                <TableCell>Supprimer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {juge.map((value, index) => (<JugeRow key={index} value={value} dispatch={dispatch} />))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Button sx={{ color: '#fff', backgroundColor: '#0063cc', mb: 3 }} variant="contained" size="medium" fullWidth
                    onClick={() => handleClickOpenAddJuge()}
                >
                    Ajouter
                </Button>
            </CardActions>
            <AddJugeCollapse openAddJuge={openAddJuge} onClose={() => handleCloseAddJuge()} dispatch={dispatch} />
        </Card>
    );
}