import React from "react";
import { HashRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Inscription from "./pages/Inscription";
import Boutique from "./pages/Boutique";
import Club from "./pages/Club";
import EspaceMembre from "./pages/Espace-membre";
import Admin from "./pages/Admin"
import NotFound from './pages/NotFound';
import AdminClub from "./pages/AdminClub";
import AdminInscription from "./pages/AdminInscription";
import AdminActivites from "./pages/AdminActivites";
import Activites from "./pages/Activités";
import Page404 from "./pages/Page404";
import MentionsLegales from "./pages/MentionsLegales";

const App = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/inscription" exact element={<Inscription />} />
        <Route path="/store" exact element={<Boutique />} />
        <Route path="/club" exact element={<Club />} />
        <Route path="/espace-membre" exact element={<EspaceMembre />} />
        <Route path="/activites" exact element={<Activites />} />
        <Route path="/store" exact element={<Boutique />} />
        <Route path="/mentions-legales" exact element={<MentionsLegales />} />
        <Route path="/*" exact element={<Page404 />} />

        <Route path="/admin" exact element={<Admin />} />
        <Route path="/admin/club" exact element={<AdminClub />} />
        <Route path="/admin/inscription" exact element={<AdminInscription />} />
        <Route path="/admin/activites" exact element={<AdminActivites />} />
        <Route element={<NotFound />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
