import React from 'react'
import Footer from '../components/Footer';
import MainLayout from '../layouts/MainLayout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function MentionsLegales() {
    return (
        <MainLayout>
            <Box>
                {/* Titre */}
                <Box sx={{ width: '80%', m: 'auto', pt: '100px' }}>
                    <Box component="div" sx={{ pt: 5, px: '25px', background: 'white' }}>
                        <Typography variant='h2' align='center' sx={{ fontSize: { xs: "2.5em", md: "5em" }, my: 5, color: 'black', py: 5, fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }} >
                            Mentions légales
                        </ Typography>
                        <Box sx={{ my: 10, px: { xs: 0, md: '50px' } }}>
                            <Typography variant='h3' sx={{ fontSize: { xs: "1.5em", md: "2em" }, my: 5, color: 'black', textAlign: 'left', fontFamily: "Roboto, Helvetica, Arial, sans-serif" }} >
                                VSF GYMNASTIQUE
                            </ Typography>
                            <Typography variant='body1' sx={{ my: 2, color: 'black', textAlign: 'justify' }} >
                                - SIRET : 79477969400015 <br/>
                                - RNA : W722000225 <br/>
                                - NUM AFFILIATION FEDE  : 52072.059
                            </ Typography>
                        </Box>
                        <Box sx={{ my: 10, px: { xs: 0, md: '50px' } }}>
                            <Typography variant='h3' sx={{ fontSize: { xs: "1.5em", md: "2em" }, my: 5, color: 'black', textAlign: 'left', fontFamily: "Roboto, Helvetica, Arial, sans-serif" }} >
                                Description des services fournis
                            </ Typography>
                            <Typography variant='body1' sx={{ my: 2, color: 'black', textAlign: 'justify' }} >
                                Le site vsf-gym.dr-kh.fr/ a pour objet de fournir une information concernant l'ensemble des activités de notre association <br />
                                Association - VSF GYM s'efforce de fournir sur le site vsf-gym.dr-kh.fr/ des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.<br />
                                Tous les informations indiquées sur le site vsf-gym.dr-kh.fr/ sont données à titre indicatif, et sont susceptibles d'évoluer. Par ailleurs, les renseignements figurant sur le site vsf-gym.dr-kh.fr/ ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.
                            </ Typography>
                        </Box>
                        <Box sx={{ my: 10, px: { xs: 0, md: '50px' } }}>
                            <Typography variant='h3' sx={{ fontSize: { xs: "1.5em", md: "2em" }, my: 5, color: 'black', textAlign: 'left', fontFamily: "Roboto, Helvetica, Arial, sans-serif" }} >
                                Propriété intellectuelle.
                            </ Typography>
                            <Typography variant='body1' sx={{ my: 2, color: 'black', textAlign: 'justify' }} >
                                Ce site web est un site généré par Kevin HUERI, à partir de données qui sont la propriété de Association - VSF GYM. <br />
                                Ce site web constitue une œuvre protégée en France par le Code de Propriété Intellectuelle, et à l'étranger par les conventions internationales en vigueur sur le droit d'auteur. La violation de l'un des droits d'auteur de l'œuvre est un délit de contrefaçon. Les informations présentées sur ce site ainsi que les liens vers d'autres sites sont donnés à titre indicatif. À l'exception de l'iconographie, la reproduction à but non lucratif des pages de ce site est autorisée à la condition expresse d'y mentionner la source et la date de capture.<br />
                                En revanche, le contenu de ce site ne peut être reproduit ou utilisé à des fins commerciales ou publicitaires. Les informations utilisées ne doivent l'être qu'à des fins personnelles, associatives ou professionnelles. Toute diffusion ou utilisation à des fins commerciales ou publicitaires est exclue.<br />
                                Aucune page ne peut être présentée comme provenant de ce site si des transformations en modifiant le sens y ont été apportées.<br />
                                Par ailleurs, Association - VSF GYM ne prend aucun engagement concernant tout autre site auquel il est possible d'avoir accès via son site web, étant expressément rappelé qu'un lien vers un autre site ne signifie aucunement que le Club assume une quelconque responsabilité quant au contenu ou l'usage qui peut être fait de tels sites. Nous essayons, autant que faire se peut, de maintenir des adresses URL de page stables. Toute demande de mise à jour ou de correction est à adresser à : vsf-gym.dr-kh.fr/ <br />
                                Aucune autorisation n'est requise pour placer un lien hypertexte vers le site de Association - VSF GYM, sur la page d'accueil ou une page intérieure du site internet. Cependant la mention explicite du site Association - VSF GYM dans l'intitulé du lien, est recommandée.
                            </ Typography>
                        </Box>
                        <Box sx={{ my: 10, px: { xs: 0, md: '50px' } }}>
                            <Typography variant='h3' sx={{ fontSize: { xs: "1.5em", md: "2em" }, my: 5, color: 'black', textAlign: 'left', fontFamily: "Roboto, Helvetica, Arial, sans-serif" }} >
                                Informatique et libertés (CNIL).
                            </ Typography>
                            <Typography variant='body1' sx={{ my: 2, color: 'black', textAlign: 'justify' }} >
                                Conformément à la loi 'informatique et libertés' du 6 janvier 1978, un droit d'accès et de rectification des informations nominatives est ouvert auprès de : Association - VSF GYM. <br />
                                Les utilisateurs sont responsables des interrogations qu'ils formulent ainsi que de l'interprétation et de l'utilisation qu'ils font des résultats. Il leur appartient d'en faire un usage conforme à la réglementation en vigueur et aux recommandations de la Commission nationale de l'informatique et des libertés (CNIL) lorsque des données ont un caractère nominatif.
                            </ Typography>
                        </Box>
                        <Box sx={{ my: 10, px: { xs: 0, md: '50px' } }}>
                            <Typography variant='h3' sx={{ fontSize: { xs: "1.5em", md: "2em" }, my: 5, color: 'black', textAlign: 'left', fontFamily: "Roboto, Helvetica, Arial, sans-serif" }} >
                                Droit applicable et attribution de juridiction.
                            </ Typography>
                            <Typography variant='body1' sx={{ my: 2, color: 'black' }} >
                                Tout litige en relation avec l'utilisation du site vsf-gym.dr-kh.fr/ est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Le Mans.
                            </ Typography>
                        </Box>
                        <Box sx={{ my: 10, px: { xs: 0, md: '50px' } }}>
                            <Typography variant='h3' sx={{ fontSize: { xs: "1.5em", md: "2em" }, my: 5, color: 'black', textAlign: 'left', fontFamily: "Roboto, Helvetica, Arial, sans-serif" }} >
                                Les principales lois concernées.
                            </ Typography>
                            <Typography variant='body1' sx={{ my: 2, color: 'black', textAlign: 'justify' }} >
                                Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux libertés.<br />
                                Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique<br />
                                Règlement (UE) n°2016/679 du 27 avril 2016 sur la protection des données à caractère personnel (dénommé « RGPD »)
                            </ Typography>
                        </Box>
                        <Box sx={{ my: 10, px: { xs: 0, md: '50px' }, pb: 10 }}>
                            <Typography variant='h3' sx={{ fontSize: { xs: "1.5em", md: "2em" }, my: 5, color: 'black', textAlign: 'left', fontFamily: "Roboto, Helvetica, Arial, sans-serif" }} >
                                Crédits photos
                            </ Typography>
                            <Typography variant='body1' sx={{ my: 2, color: 'black' }} >
                                Tout droit : Association, VSF GYM.
                            </ Typography>
                        </Box>

                    </Box>
                </Box>
            </Box>
            {/* Footer */}
            <Footer />

        </MainLayout>
    )
}

export default MentionsLegales
