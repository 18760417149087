/*
 * React
 * ***** */
import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

/*
 * Reducers
 * ******** */
import { UserReducer } from "./reducers/User";
import { ActivitesReducer } from "./reducers/Activites";
import { CalendrierReducer } from "./reducers/Calendrier";
import { ChampionsReducer } from "./reducers/Champions";
import { EntraineursReducer } from "./reducers/Entraineurs";
import { EquipeAdministrativeReducer } from "./reducers/EquipeAdministrative";
import { JugesReducer } from "./reducers/Juges";
import { ProceduresReducer } from "./reducers/Procedures";
import { LinkReducer } from "./reducers/Link";
import { SalleReducer } from "./reducers/Salle";
import { TarifsReducer } from "./reducers/Tarifs";
import { YearsInscriptionReducer } from "./reducers/YearsInscription";
import { HistoireClubReducer } from "./reducers/HistoireClub";
import { AuthReducer } from "./reducers/Auth";
import { ImageInscriptionReducer } from "./reducers/ImageInscription";
import { BoutiqueReducer } from "./reducers/Boutique";

/*
 * All (Root) Reducers
 * ******************* */
const rootReducer = combineReducers({
  user: UserReducer,
  activites: ActivitesReducer,
  calendrier: CalendrierReducer,
  champions: ChampionsReducer,
  entraineurs: EntraineursReducer,
  equipeAdministrative: EquipeAdministrativeReducer,
  juges: JugesReducer,
  procedures: ProceduresReducer,
  link: LinkReducer,
  salle: SalleReducer,
  tarifs: TarifsReducer,
  years: YearsInscriptionReducer,
  histoireClub: HistoireClubReducer,
  auth: AuthReducer,
  imageInscription: ImageInscriptionReducer,
  boutique: BoutiqueReducer
});

/*
 * Store export
 * ************ */
export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
); // Dev
// export const store = createStore(rootReducer); // Prod
