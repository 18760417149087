import React, { useEffect } from 'react';
import MainLayout from '../layouts/MainLayout';
import CssBaseline from "@mui/material/CssBaseline";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { urlImg } from "../utils/url";
import ScrollButton from '../components/ScrollButton';
import Footer from '../components/Footer';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { EffectCoverflow, Pagination } from "swiper";
import SwiperCore, { Autoplay } from 'swiper';

import { useSelector, useDispatch } from "react-redux";
import { getActivites } from '../store/actions/ActivitesActions';

function ActivitesRow(props) {
    const { value } = props;
    const imageActivites = value.activitesImagesId;
    SwiperCore.use([Autoplay])

    return (
        <React.Fragment>
            <Box
                sx={{
                    position: 'relative',
                    height: { xs: '100%', md: '450px' },
                    width: { xs: '80%', md: '800px', lg: '1000px' },
                    m: '200px auto',
                    backgroundColor: '#fff',
                    boxShadow: '10px 10px 93px 0px rgba(0, 0, 0, 0.75)',
                }}
            >
                <Box className="imageActivities"
                    sx={{
                        float: { md: 'left' },
                        position: { md: 'relative' },
                        left: { md: '-30px', lg: "-60px" },
                        top: { md: "-40px", lg: "-80px" },
                        height: { md: '400px', lg: "500px" },
                        width: { md: '600px', lg: "800px" },
                        overflow: 'hidden',
                        boxShadow: { md: '10px 10px 60px 0px rgba(0, 0, 0, 0.75)' }
                    }}
                >
                    {/* Titre activité */}
                    <Swiper
                        loop={true}
                        effect={"coverflow"}
                        autoplay={{ delay: 2000 }}
                        slidesPerView={1}
                        spaceBetween={5}
                        pagination={{ clickable: true, }}
                        modules={[EffectCoverflow, Pagination]}
                        className="left"
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        sx={{
                            position: { xs: 'relative', md: 'absolute' },
                            left: { xs: '100%', md: '50%' },
                            top: { xs: '100%', md: '50%' },
                        }}
                    >
                        {imageActivites.map((step, index) => (
                            <SwiperSlide key={index}>
                                <img width="100%" src={`${urlImg + step.image}`} alt={step.name} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
                <Box sx={{ ml: { md: '590px' }, mr: { md: '20px' } }}>
                    <Typography variant='h2'
                        sx={{ pt: '15px', fontSize: '1.5rem', fontFamily: "Roboto, Helvetica, Arial, sans-serif", color: '#4B4B4B' }} >
                        {value.titre}
                    </Typography>
                    <Box
                        sx={{
                            my: '30px',
                            border: '1px solid #C3C3C3',
                            width: { xs: '80%', md: '100%' },
                            mx: 'auto'
                        }}
                    ></Box>
                    <Typography variant='body1'
                        sx={{
                            textAlign: 'center',
                            pt: '10px',
                            pb: { xs: 10, md: 0 },
                            px: { xs: 10, md: 0 },
                            fontSize: '1rem',
                            lineHeight: '150%',
                            color: '#4B4B4B',
                            position: 'relative',
                            left: { xs: '0px', lg: "-30px" }
                        }}
                    >
                        {value.description}
                    </Typography>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default function Activites() {
    const dispatch = useDispatch();
    const activities = useSelector((state) => state.activites.activitesData)

    useEffect(() => { dispatch(getActivites()) }, [dispatch]);

    return (
        <MainLayout>
            <CssBaseline />

            {/* Titre */}
            <Box component="div" sx={{ pt: 5, padding: '85px 16px 24px' }}>
                <Typography variant='h2' align='center' sx={{ fontSize: { xs: "2.5em", md: "5em" }, my: 2 }} >
                    Nos activités
                </ Typography>
            </Box>

            {/* Les activités */}
            <Box component="div" sx={{ py: 5, my: "20px", mx: 'auto', display: 'block', width: '100%', borderRadius: 1 }}>
                {activities.map((value, index) => (
                    <ActivitesRow key={index} value={value} />
                ))}
            </Box>

            <ScrollButton />

            {/* Footer */}
            <Footer />

        </MainLayout>
    );
};