/*
Un petit club créé en 1971, dans une toute petite salle qui grâce à de nombreux bénévoles à pu grandir. En 2012 un projet d'agrandissement vu le jour et le club passe de 150 licencié à plus de 300 en quelque années. Suivent ensuite les résultats excellent sur les compétitions nationales, l'embauche de plusieurs salariés et la mise en place d'action plus sympa les unes que les autres. Le VSF c'est une grande famille tous uni pour la même passion LA GYMNASTIQUE !!!
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
    GET_EQUIPEADMINISTRATIVE_DATA,
    ADD_EQUIPEADMINISTRATIVE_DATA,
    DELETE_EQUIPEADMINISTRATIVE_DATA,
    EDIT_EQUIPEADMINISTRATIVE_DATA,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// Get Equipe Administrative
export const getEquipeAdministrative = () => {
    return (dispatch) => {
        return api
            .get("/equipe-administrative/")
            .then((res) => {
                dispatch({ type: GET_EQUIPEADMINISTRATIVE_DATA, payload: res.data.dbEquipeAdministrative });
            })
            .catch((err) => console.log(err));
    };
};

// Add Equipe Administrative
export const addEquipeAdministrative = (data) => {
    return (dispatch) => {
        return api
            .post("/equipe-administrative/", data)
            .then((res) => {
                dispatch({ type: ADD_EQUIPEADMINISTRATIVE_DATA, payload: res.data.dbEquipeAdministrative });
            })
            .catch((err) => console.log(err));
    };
};

// DeleteOne Equipe Administrative
export const deleteEquipeAdministrative = (id) => {
    return (dispatch) => {
        return api
            .delete(`/equipe-administrative/${id}`)
            .then((res) => {
                dispatch({ type: DELETE_EQUIPEADMINISTRATIVE_DATA, payload: res.data.dbEquipeAdministrative });
            })
            .catch((err) => console.log(err));
    };
};

// Edit Equipe Administrative
export const editEquipeAdministrative = (id, data) => {
    return (dispatch) => {
        return api
            .put(`/equipe-administrative/${id}`, data)
            .then((res) => {
                dispatch({ type: EDIT_EQUIPEADMINISTRATIVE_DATA, payload: res.data.dbEquipeAdministrative });
            })
            .catch((err) => console.log(err));
    };
}